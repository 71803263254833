import { useLayoutEffect, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, IconName, Message, Modal } from '@cutover/react-ui'
import { useLanguage, useWorkspacePermittedProjects } from 'main/services/hooks'
import { MergeRunbooksPayload, useMergeRunbooks } from 'main/services/queries/merge-runbooks-query'
import { useValidateMerge } from 'main/services/queries/validate-merge-query'
import {
  useAccount,
  useAccountCustomFieldGroups,
  useAccountCustomFields,
  useAccountCustomFieldUsers
} from 'main/services/api/data-providers/account/account-data'
import {
  buildMergeRunbookMergeSchema,
  buildNewRunbookDetailsSchema,
  buildValidateMergeSchema,
  MergeModalMergeRunbooksFormType,
  MergeModalNewRunbookDetailsFormType,
  MergeModalValidateMergeFormType
} from './merge-runbooks-modal-types'
import {
  StepChooseMergeType,
  StepMergeValidationSummary,
  StepNewRunbookChooseType,
  StepNewRunbookDetails
} from './steps'
import { MergeRunbooksModalProvider, useMergeRunbooksContext } from './merge-runbooks-modal-provider'
import { Form, FormType } from 'main/components/shared/form/form'
import { useCustomFieldForm } from 'main/components/shared/custom-field-form'
import { buildDefaultFieldValues } from 'main/components/shared/custom-field-form/custom-field-form-helper'
import { useScrollIntoView } from 'main/components/shared/hooks/use-scroll-into-view'
import { useFolderDefaultOption } from 'main/components/shared/form/use-folder-options'
import { useRouting } from 'main/services/routing/hooks'
import { useWorkspaceData } from 'main/services/api/data-providers/workspace'

type MergeRunbooksModalProps = {
  runbookIds: number[]
  open: boolean
  canCreateRunbooks: boolean
  closeModal: () => void
}

export const MergeRunbooksModal = ({ runbookIds, ...props }: MergeRunbooksModalProps) => {
  const { account } = useAccount()

  return account?.id && props.open ? (
    <MergeRunbooksModalProvider runbookIds={runbookIds} accountId={account.id}>
      <MergeRunbooks runbookIds={runbookIds} {...props} />
    </MergeRunbooksModalProvider>
  ) : null
}

const MergeRunbooks = ({ open, canCreateRunbooks, closeModal }: MergeRunbooksModalProps) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'mergeModal' })
  const { ref: formWrapperRef, scrollIntoView } = useScrollIntoView()
  const navigate = useNavigate()
  const {
    step,
    setStep,
    mergeType,
    setMergeValidationSummary,
    mergeValidationSummary,
    accountId,
    newRunbookTypeId,
    runbookIds,
    accountSlug
  } = useMergeRunbooksContext()

  const { customFieldsLookup } = useAccountCustomFields()
  const { customFieldUsers } = useAccountCustomFieldUsers()
  const { customFieldGroupsLookup, customFieldGroups } = useAccountCustomFieldGroups()
  const { projects: folders } = useWorkspacePermittedProjects({ accountId, enabled: mergeType === 'new' })
  const defaultFolder = useFolderDefaultOption({ folders: folders ?? [] })
  const { toRunbook } = useRouting()
  const { runbooks } = useWorkspaceData()

  const currentSelectedRunbook = runbookIds.length === 1 && runbooks?.find(rb => rb.id === runbookIds[0])
  const lockDestinationProject =
    (currentSelectedRunbook && currentSelectedRunbook.settings_lock_template_copies_to_folder) || false
  const destinationProjectId = (currentSelectedRunbook && currentSelectedRunbook.project_id) || undefined

  const {
    fieldValueValidation,
    buildFieldValuesAttributesRequestData,
    data: { customFields, groupedCustomFields }
  } = useCustomFieldForm({
    applyToSlugs: ['runbook_add_edit'],
    customFieldsLookup,
    customFieldGroups,
    constraintContext: { runbook_type_id: newRunbookTypeId }
  })

  const validateMutation = useValidateMerge()
  const mergeMutation = useMergeRunbooks()

  const isLoadingValidationCheck = validateMutation.isLoading
  const isLoadingMerge = mergeMutation.isLoading
  const isLoading = isLoadingValidationCheck || isLoadingMerge

  const validateMergeFormRef = useRef<FormType<MergeModalValidateMergeFormType>>(null)
  const newRunbookDetailsFormRef = useRef<FormType<MergeModalNewRunbookDetailsFormType>>(null)
  const mergeRunbooksFormRef = useRef<FormType<MergeModalMergeRunbooksFormType>>(null)

  const handleClickBack = () => {
    switch (step) {
      case 'choose-new-runbook-type':
        setStep('choose-merge-type')
        break
      case 'enter-new-runbook-details':
        setStep('choose-new-runbook-type')
        break
      case 'validation-summary':
        setStep(mergeType === 'existing' ? 'choose-merge-type' : 'enter-new-runbook-details')
        break
    }
  }

  const handleSubmitValidateMerge = async (form: MergeModalValidateMergeFormType) => {
    const { runbook_ids, target_runbook } = form

    return validateMutation.mutateAsync(
      { runbook_ids, target_runbook_id: target_runbook.id },
      {
        onSuccess: data => {
          setMergeValidationSummary(data)
          if (mergeType === 'existing') {
            setStep('validation-summary')
          } else {
            setStep('choose-new-runbook-type')
          }
        }
      }
    )
  }

  const handleSubmitMerge = ({ target_type: _, ...data }: any) => {
    return mergeMutation.mutateAsync(data, {
      onSuccess: data => {
        const { runbook } = data
        closeModal()
        navigate(toRunbook({ accountSlug: accountSlug as string, runbookId: runbook.id }))
      }
    })
  }

  const modalTitle = useMemo(() => {
    switch (step) {
      case 'choose-merge-type':
        return t('mergeRunbooks', { count: runbookIds.length })
      case 'choose-new-runbook-type':
        return t('chooseRunbookType')
      case 'enter-new-runbook-details':
        return t('enterDetails')
      case 'validation-summary':
        return t('confirmMerge')
    }
  }, [step, runbookIds.length])

  const confirmText = useMemo(() => (step === 'validation-summary' ? t('merge') : t('next')), [step])
  const confirmIcon: IconName = useMemo(() => (step === 'validation-summary' ? 'merge' : 'arrow-forward'), [step])

  const handleClickConfirm = () => {
    switch (step) {
      case 'choose-merge-type':
        validateMergeFormRef.current?.submit()
        break
      case 'choose-new-runbook-type':
        setStep('enter-new-runbook-details')
        break
      case 'enter-new-runbook-details':
        newRunbookDetailsFormRef.current?.validate().then(isValid => {
          if (isValid) {
            setStep('validation-summary')
          } else {
            scrollIntoView()
          }
        })
        break
      case 'validation-summary':
        mergeRunbooksFormRef.current?.submit()
    }
  }

  useLayoutEffect(() => {
    mergeRunbooksFormRef.current?.clearErrors()
    newRunbookDetailsFormRef.current?.clearErrors()
    validateMergeFormRef.current?.clearErrors()
  }, [step])

  let content

  if (step === 'choose-merge-type') {
    content = (
      <Form<MergeModalValidateMergeFormType>
        key="validate-runbook-merge"
        ref={validateMergeFormRef}
        defaultValues={
          mergeRunbooksFormRef.current?.getValues() ?? {
            target_type: canCreateRunbooks ? 'new' : 'existing',
            runbook_ids: runbookIds,
            target_runbook: undefined
          }
        }
        shouldFocusError={false}
        schema={buildValidateMergeSchema(mergeType)}
        onSubmit={handleSubmitValidateMerge}
      >
        {({ errorMessage }) => (
          <Box gap="medium">
            <Message type="error" message={errorMessage} data-testid="form-error-message" />
            <Box>
              <StepChooseMergeType canCreateRunbooks={canCreateRunbooks} />
            </Box>
          </Box>
        )}
      </Form>
    )
  } else if (step === 'choose-new-runbook-type') {
    content = <StepNewRunbookChooseType />
  } else if (step === 'enter-new-runbook-details') {
    content = (
      <Form<MergeModalNewRunbookDetailsFormType>
        key="new-runbook-details"
        ref={newRunbookDetailsFormRef}
        mode="onChange"
        defaultValues={async () => {
          const defaultVals = mergeRunbooksFormRef?.current?.getValues()

          if (defaultVals) {
            return defaultVals
          } else {
            return {
              runbook_ids: runbookIds,
              field_values: buildDefaultFieldValues(customFields, groupedCustomFields),
              shift_time: false,
              runbook: {
                account_id: accountId,
                project_id: destinationProjectId || defaultFolder?.id,
                runbook_type_id: newRunbookTypeId
              }
            }
          }
        }}
        schema={buildNewRunbookDetailsSchema(fieldValueValidation)}
      >
        {({ errorMessage }) => (
          <Box gap="medium" ref={formWrapperRef}>
            <Message type="error" message={errorMessage} data-testid="form-error-message" />
            <Box>
              <StepNewRunbookDetails
                folders={folders}
                customFieldGroupsLookup={customFieldGroupsLookup}
                customFieldUsers={customFieldUsers}
                customFields={customFields}
                groupedCustomFields={groupedCustomFields}
                lockDestinationProject={lockDestinationProject}
              />
            </Box>
          </Box>
        )}
      </Form>
    )
  } else if (step === 'validation-summary') {
    content = (
      <Form<MergeModalMergeRunbooksFormType, MergeRunbooksPayload>
        key="merge-runbook-form"
        ref={mergeRunbooksFormRef}
        formElementWrapper={false}
        defaultValues={
          mergeType === 'existing'
            ? validateMergeFormRef.current?.getValues()
            : newRunbookDetailsFormRef.current?.getValues()
        }
        transformer={({ field_values, target_runbook, ...rest }) => {
          const data = rest as MergeRunbooksPayload
          if (mergeType === 'existing') {
            return {
              ...data,
              target_runbook_id: target_runbook?.id
            }
          } else {
            return {
              ...data,
              runbook: data.runbook
                ? {
                    ...data.runbook,
                    field_values_attributes: buildFieldValuesAttributesRequestData(field_values)
                  }
                : undefined
            }
          }
        }}
        schema={buildMergeRunbookMergeSchema(fieldValueValidation)}
        onSubmit={handleSubmitMerge}
        errorMessage={t('errorMessage')}
      >
        {({ errorMessage }) => (
          <StepMergeValidationSummary errorMessage={errorMessage} summary={mergeValidationSummary} />
        )}
      </Form>
    )
  } else {
    null
  }

  return (
    <Modal
      open={open}
      onClose={closeModal}
      onClickConfirm={handleClickConfirm}
      title={modalTitle}
      confirmText={confirmText}
      confirmIcon={confirmIcon}
      focusConfirmButton={step === 'validation-summary'}
      hideFooter={step === 'choose-new-runbook-type'}
      loading={isLoading}
      onClickBack={step === 'choose-merge-type' ? undefined : handleClickBack}
    >
      {content}
    </Modal>
  )
}
