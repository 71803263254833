// @ts-nocheck
import { observable, action } from 'mobx'
import { injectable } from 'inversify'
import { unescape } from 'lodash'
import { GenericListRepository } from '../GenericListRepository'
import { QueryStringBuilder } from 'Shared/Helpers/QueryStringBuilder'
import { container } from 'app/Config/IOC'
import { IBaseResponse } from 'Gateways/Service/IBaseResponse'
import { IRunbookTemplatePM } from 'Shared/Entities/Types/Runbooks/IRunbookTemplatePM'
import { IRunbookTemplateDTO } from 'Shared/Entities/Types/Runbooks/IRunbookTemplateDTO'

@injectable()
export class RunbookTemplatesRepository extends GenericListRepository {
  constructor() {
    super('meta')
  }

  @observable
  public runbookTemplates: IRunbookTemplatePM[] = []

  @observable
  public filteredListTotal: number = 0

  @observable
  public offSet: number = 0

  @observable
  public batchLoadNumber: number = 20

  protected queryStringBuilder: QueryStringBuilder = container.get(QueryStringBuilder)

  @action
  public preLoad = (params: { [key: string]: any }): string => {
    const { accountId, runbookTypeId, offSet, value, onlyApproved = false } = params
    this.offSet = offSet
    const queryParams: { [key: string]: any } = {
      t: true,
      accountId,
      type: [runbookTypeId],
      fields: [
        'name',
        'id',
        'runbook_type_id',
        'copies_count',
        'timezone',
        'end_scheduled',
        'start_scheduled',
        'start_planned',
        'template_status',
        'project_name',
        'tasks_count',
        'field_values',
        'current_template_version_approval_status'
      ],
      sort_by: 'copies_count',
      sort_dir: 'desc',
      offset: this.offSet,
      limit: this.batchLoadNumber
    }

    if (onlyApproved) queryParams['ts'] = 'template_approved'
    const query = value ? `&q=${value}` : ''

    return `runbooks?${this.queryStringBuilder.getCustomQueryString(queryParams)}${query}`
  }

  @action
  public postLoad = (rawData: IBaseResponse) => {
    this.filteredListTotal = rawData.body.meta.filtered_results_count
    this.runbookTemplates =
      this.offSet === 0
        ? this.getRunbookTemplatesFromAPIData(rawData)
        : this.runbookTemplates.concat(this.getRunbookTemplatesFromAPIData(rawData))
  }

  private getRunbookTemplatesFromAPIData = (rawData: IBaseResponse): IRunbookTemplatePM[] => {
    return rawData.body.runbooks.map((runbook: IRunbookTemplateDTO) => {
      return {
        id: runbook.id,
        name: unescape(runbook.name),
        copiesCount: runbook.copies_count,
        runbookTypeId: runbook.runbook_type_id,
        timezone: runbook.timezone,
        endScheduled: runbook.end_scheduled,
        startScheduled: runbook.start_scheduled,
        startPlanned: runbook.start_planned,
        templateStatus: runbook.template_status,
        projectName: runbook.project_name,
        tasksCount: runbook.tasks_count,
        currentTemplateVersionApprovalStatus: runbook.current_template_version_approval_status,
        fieldValues: runbook.field_values
      }
    })
  }

  @action
  public clear = () => {
    this.clearInherited()
    this.runbookTemplates = []
  }
}
