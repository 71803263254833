import { useFocused, useSelected, useSlateStatic } from 'slate-react'
import styled from 'styled-components/macro'

import { IconButton } from '../../../button'
import { Box } from '../../../layout/box'
import { themeColor, themeElevation } from '../../../theme'
import { removeLink } from '../utils'
import { RenderElement } from './element'
import { LinkElement } from '../text-editor-types'

export const Link = ({ attributes, children, element, readOnly }: RenderElement) => {
  const editor = useSlateStatic()
  const selected = useSelected()
  const focused = useFocused()
  const { url } = element as LinkElement

  const handleLinkClick = () => {
    window.open(url, '_newtab')
  }

  return (
    <>
      {readOnly ? (
        <StyledLink href={url} onClick={handleLinkClick} rel="noreferrer" target="_blank">
          {children}
        </StyledLink>
      ) : (
        <span style={{ position: 'relative' }}>
          <StyledLink {...attributes}>{children}</StyledLink>
          {selected && focused && (
            <LinkPopopver direction="row" align="center" contentEditable={false}>
              <span
                css={`
                  display: inline-block;
                  max-width: 215px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                `}
              >
                <StyledLink href={url} onClick={handleLinkClick} rel="noreferrer" target="_blank">
                  {url}
                </StyledLink>
              </span>
              <IconButton
                size="medium"
                icon="cancel"
                label="Remove link"
                disableTooltip
                onClick={() => removeLink(editor)}
                css={`
                  position: relative;
                  margin-left: 4px;
                  top: 1px;
                `}
              />
            </LinkPopopver>
          )}
        </span>
      )}
    </>
  )
}

const StyledLink = styled.a`
  color: ${themeColor('primary')};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const LinkPopopver = styled(Box)`
  z-index: 1;
  position: absolute;
  white-space: nowrap;
  userselect: 'none';
  margin-top: 4px;
  border-radius: 8px;
  border: none;
  width: auto;
  box-shadow: ${themeElevation('medium')};
  background: ${themeColor('menu-bg')};
  padding: 6px 8px;
  max-width: 250px;
  line-height: 1rem;
`
