import { useMutation } from 'react-query'

import { FieldValuesAttributes } from './types/custom-fields'
import { apiClient } from '../api'
import { ApiError } from 'main/services/api/http-gateway-adapter'

type DuplicateRunbookRequestType = {
  name: string
  copy_tasks: boolean
  copy_teams: boolean
  copy_users: boolean
  template_type?: string
  template_status?: string
  task_internal_ids?: number[]
  create_snippet?: boolean
  field_values_attributes?: FieldValuesAttributes[]
  project_id?: number
  shift_time?: boolean
  runbook_versions_attributes?: [
    {
      end_scheduled?: Date
      start_scheduled?: Date
      start_planned?: Date
    }
  ]
}

export type DuplicateRunbookResponseType = {
  id: number
  account_slug: string
}

export function useDuplicateRunbook(runbookId: number | undefined) {
  return useMutation<DuplicateRunbookResponseType, ApiError, DuplicateRunbookRequestType>(
    `duplicate-runbook-${runbookId}`,
    async payload => {
      const { data } = await apiClient.patch({
        url: `runbooks/${runbookId}/duplicate`,
        data: payload,
        requestProperty: 'runbook',
        responseProperty: 'runbook'
      })

      return data as DuplicateRunbookResponseType
    }
  )
}

type BulkDuplicateRunbooksRequestType = {
  options: {
    suffix?: string
    copy_tasks: boolean
    copy_teams: boolean
    copy_users: boolean
    template_type?: string
    template_status?: string
    is_template?: boolean
    project_id?: number
    shift_time?: boolean
    start_planned?: Date
    start_scheduled?: Date
    end_scheduled?: Date
  }
  runbook?: {
    field_values_attributes: FieldValuesAttributes[]
  }
  runbook_ids: number[]
}

export function useBulkDuplicateRunbooks(runbookIds: number[] | []) {
  return useMutation<unknown, ApiError, BulkDuplicateRunbooksRequestType>(
    [...runbookIds.map(runbookId => `duplicate-runbooks-${runbookId}`)],
    async payload => {
      await apiClient.patch({
        url: 'runbooks/bulk_duplicate',
        data: payload,
        responseProperty: 'runbook'
      })
    }
  )
}
