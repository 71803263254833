import { ReactNode } from 'react'

import { Box } from '../../layout/box/box'
import { Text } from '../../typography/text/text'

const DEFAULT_TIME_DISPLAY = '--:--'

export type CompletionMetricTileProps = {
  label: string
  timeString?: string
  dateString?: string
  diff?: {
    value: string
    type: 'early' | 'late'
  }
}

export const CompletionMetricTile = (props: CompletionMetricTileProps) => {
  const { label, timeString, dateString, diff } = props
  return (
    <Box pad="medium" background="bg-1">
      <Label>{label}</Label>
      <Time>{timeString || DEFAULT_TIME_DISPLAY}</Time>
      <Date>{dateString}</Date>
      <Diff value={diff?.value} type={diff?.type} />
    </Box>
  )
}

const Label = ({ children }: { children: ReactNode }) => {
  return (
    <Text
      weight={700}
      size="medium"
      color="text-light"
      css={`
        text-transform: uppercase;
      `}
    >
      {children}
    </Text>
  )
}

const Time = ({ children }: { children: ReactNode }) => {
  // max height = size + vertical padding so that if the content wraps multiple
  // lines (really only happens on duration tiles), it doesn't expand the overall height of the tile
  return (
    <Text
      size="xxlarge"
      weight={300}
      color="text-light"
      css={`
        padding: '16px';
        max-height: '40px';
      `}
    >
      {children}
    </Text>
  )
}

const Date = ({ children }: { children: ReactNode }) => {
  return (
    <Text color="text-light" size="small">
      {children}
      {'\u00A0'}
    </Text>
  )
}

const Diff = ({ value, type }: { value?: string; type?: 'early' | 'late' }) => {
  return (
    <Text size="small" color={type && type === 'early' ? 'success' : 'warning'}>
      {value && type ? (type === 'early' ? '-' : '+') : '\u00A0'} {value}
    </Text>
  )
}
