import styled from 'styled-components'

import { Avatar, LegacyBox as Box, colors, LegacyFlex as Flex, px2rem, LegacyText as Text } from '@cutover/react-ui'

export type DashboardUserItemProps = {
  name: string
  color: string
  handle: string
}

export function DashboardUserItem({ name, color, handle }: DashboardUserItemProps) {
  return (
    <DashboardUserItemWrapper data-testid="user-item" alignItems="center" justifyContent="space-between">
      <Flex
        alignItems="center"
        css={`
          flex: 1;
          min-width: 0;
        `}
      >
        <UserAvatarDiv>
          <Avatar subject={{ name, color }} />
        </UserAvatarDiv>
        <Text data-testid="dashboard-user-item-name" color={colors.lightBlack} size={15} truncate>
          {name}
        </Text>
      </Flex>
      <Box
        css={`
          margin-left: ${px2rem(5)};
        `}
      >
        <Text data-test-id="dashboard-user-item-handle">{`@${handle}`}</Text>
      </Box>
    </DashboardUserItemWrapper>
  )
}

const UserAvatarDiv = styled.div`
  margin-right: ${px2rem(6)};
  flex-shrink: 0;
`

const DashboardUserItemWrapper = styled(Flex)`
  height: ${px2rem(32)};
`
