import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Box, Button, Message } from '@cutover/react-ui'
import { AuthenticationFormWrapper } from './authentication-form-wrapper'
import { LoginPageOption } from './login-page'
import { FooterLinkText, LoggedOutHeading } from './login-styled'
import { LoginWarningMessage } from './login-warning-message'
import { useLanguage } from 'main/services/hooks'
import { redirectToSSO } from 'main/services/queries/use-login'

export const SSOForm = ({
  showEmailLink,
  handleLoginPageOption,
  loginWarningMessage
}: {
  showEmailLink: boolean
  handleLoginPageOption: (option: LoginPageOption) => void
  loginWarningMessage: string
}) => {
  const [logoutState] = useState<{ success?: string[]; error?: string[] }>(
    JSON.parse(localStorage.getItem('reactLogout') ?? '{}')
  )
  const [resetPasswordSuccess] = useState(localStorage.getItem('resetPasswordSuccess'))
  const [userVerifySuccess] = useState(localStorage.getItem('userVerifySuccess'))
  const [showRedirectMessage] = useState(localStorage.getItem('showRedirectMessage'))
  const [reactForbidden] = useState(localStorage.getItem('reactForbidden'))
  const [blockLogin, setBlockLogin] = useState(Boolean(loginWarningMessage))
  const { t } = useLanguage('authentication')
  const [searchParams] = useSearchParams()

  const invalidSSO = searchParams.get('auth_type') === 'failure'

  const handleSSORedirect = () => {
    redirectToSSO()
  }

  const toggleBlockLogin = () => {
    setBlockLogin(!blockLogin)
  }

  useEffect(() => {
    localStorage.removeItem('reactLogout')
  }, [logoutState])

  useEffect(() => {
    localStorage.removeItem('showRedirectMessage')
  }, [showRedirectMessage])

  useEffect(() => {
    localStorage.removeItem('reactForbidden')
  }, [reactForbidden])

  useEffect(() => {
    localStorage.removeItem('userVerifySuccess')
  }, [userVerifySuccess])

  useEffect(() => {
    localStorage.removeItem('resetPasswordSuccess')
  }, [resetPasswordSuccess])

  return (
    <>
      <Box width="320px">
        <LoggedOutHeading>{t('loginHeading')}</LoggedOutHeading>
        <AuthenticationFormWrapper>
          {reactForbidden && <Message message={t('forbiddenMessage')} type="error" margin />}
          {showRedirectMessage && <Message message={t('redirectMessage')} type="error" margin />}
          {logoutState?.success?.includes('user-updated') && (
            <Message message={t('userUpdatedMessage')} type="success" margin />
          )}
          {logoutState?.success?.includes('logout') && <Message message={t('logOutMessage')} type="success" margin />}
          {userVerifySuccess && <Message message={t('userVerifyMessage')} type="success" margin />}
          {invalidSSO && <Message message={t('loginError.invalidCredentials')} type="error" margin />}
          {resetPasswordSuccess && <Message message={t('passwordResetSuccess')} type="success" margin />}
          <Message type="info" message={t('ssoInfo')} margin />
          {Boolean(loginWarningMessage) && (
            <LoginWarningMessage onClick={toggleBlockLogin} message={loginWarningMessage} />
          )}
          <Box margin={{ top: 'small' }}>
            <Button
              primary
              full
              type="submit"
              onClick={handleSSORedirect}
              label={t('submitLogin')}
              loadingLabel={t('submittingLogin')}
              icon="arrow-forward"
              disabled={blockLogin}
            />
          </Box>
        </AuthenticationFormWrapper>
      </Box>
      <Box alignSelf="center" margin={{ top: 'medium' }} css="text-align: center;">
        {showEmailLink && (
          <FooterLinkText onClick={() => handleLoginPageOption('email')}>{t('loginWithEmail')}</FooterLinkText>
        )}
      </Box>
    </>
  )
}
