import { Editor, Node, Path, Range, Element as SlateElement, Transforms } from 'slate'
import { ReactEditor } from 'slate-react'

import { LinkPayload } from '../components/text-editor-link-modal'
import { LinkElement, ParagraphElement } from '../text-editor-types'

const createLinkNode = (url: string, text: string): LinkElement => ({
  type: 'link',
  url,
  children: [{ text }]
})

export const removeLink = (editor: Editor, opts = {}) => {
  Transforms.unwrapNodes(editor, {
    ...opts,
    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link'
  })
}

const createParagraphNode = (children: LinkElement[]): ParagraphElement => ({
  type: 'paragraph',
  children
})

export const insertLink = (editor: Editor, data: LinkPayload) => {
  if (!data.url) return

  const { selection } = editor
  const link = createLinkNode(data.url, data.text || 'New link')

  ReactEditor.focus(editor)

  if (!!selection) {
    const [parentNode, parentPath] = Editor.parent(editor, selection.focus?.path)

    if ((parentNode as SlateElement).type === 'link') {
      removeLink(editor)
    }

    if (editor.isVoid(parentNode as SlateElement)) {
      Transforms.insertNodes(editor, createParagraphNode([link]) as SlateElement, {
        at: Path.next(parentPath),
        select: true
      })
    } else if (Range.isCollapsed(selection)) {
      Transforms.insertNodes(editor, link as Node, { select: true })
    } else {
      Transforms.wrapNodes(editor, link as SlateElement, { split: true })
      Transforms.collapse(editor, { edge: 'end' })
    }
  } else {
    Transforms.insertNodes(editor, createParagraphNode([link]) as SlateElement)
  }
}
