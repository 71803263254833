import { Editor, Node, Element as SlateElement, Transforms } from 'slate'

import { Align, CustomElement, Type } from '../text-editor-types'

const LIST_TYPES = ['bulleted-list', 'numbered-list']
export const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

export const isBlockActive = (editor: Editor, format: Type | Align, blockType = 'type') => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n: Node) => !Editor.isEditor(n) && SlateElement.isElement(n) && (n as any)[blockType] === format
    })
  )

  return !!match
}

export const toggleBlock = (editor: Editor, format: Type | Align) => {
  const isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type')
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true
  })

  let newProperties: {}
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format
    }
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format
    }
  }
  Transforms.setNodes<SlateElement>(editor, newProperties)

  if (!isActive && isList) {
    const block: CustomElement = { type: format as Type, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}
