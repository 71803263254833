import { useRef } from 'react'
import { useButton } from '@react-aria/button'
import { useLocale } from '@react-aria/i18n'
import { useNumberField } from '@react-aria/numberfield'
import { NumberFieldStateOptions, useNumberFieldState } from '@react-stately/numberfield'
import styled from 'styled-components/macro'
import { useMergeRefs } from 'use-callback-ref'

import { IconButton } from '../../button'
import { Box } from '../../layout'
import { themeColor } from '../../theme'
import { FieldBox } from '../internal/form-field'

type NumberFieldProps = {
  value: number
  onChange: (value: number) => void
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void
  inputRef?: any
  maxLength?: number
}

const MAX_DIGITS = 10

// NOTE: NumberField uses useNumberField from react-aria
export const NumberField = (
  props: Omit<NumberFieldStateOptions, 'locale' | 'value' | 'onChange' | 'onClick'> & NumberFieldProps
) => {
  const { locale } = useLocale()
  const localInputRef = useRef<HTMLInputElement>(null)
  const mergedRef = useMergeRefs<HTMLInputElement>(props.inputRef ? [props.inputRef, localInputRef] : [localInputRef])

  const state = useNumberFieldState({ ...props, locale })
  const { groupProps, inputProps, incrementButtonProps, decrementButtonProps } = useNumberField(props, state, mergedRef)

  const value = state.inputValue
  // NOTE: This handleChange validates the max digits you can type (maxLength)
  // since there is no prop that sets this value on a [type="number"].
  const handleChange = (e: any) => {
    e.preventDefault()
    if (e.target.value.length <= (props?.maxLength ?? MAX_DIGITS)) {
      state.setInputValue(e.target.value)
    }
  }

  // NOTE: needed to go hide button when disabled or readOnly
  // css (opacity) gets overritten by buttonProps (ArrowButton) to 0.5, so it would show up when disabled
  const showButton = !(props.isDisabled || props.isReadOnly)

  return (
    <NumberPicker {...groupProps} gap="small" height="24px" justify="center" align="center">
      {showButton && (
        <ArrowButton
          {...incrementButtonProps}
          icon="caret-up"
          label="Increment"
          isDisabled={props.isDisabled || props.isReadOnly}
        />
      )}
      <NumberInput
        {...inputProps}
        type="number"
        value={value}
        onChange={handleChange}
        ref={mergedRef}
        isDisabled={props.isDisabled}
        aria-label={typeof props.label === 'string' ? props.label : undefined}
      />
      {showButton && (
        <ArrowButton
          {...decrementButtonProps}
          icon="caret-down"
          label="Decrement"
          isDisabled={props.isDisabled || props.isReadOnly}
        />
      )}
    </NumberPicker>
  )
}

const NumberPicker = styled(Box)``

function ArrowButton(props: any) {
  let ref = useRef(null)
  const { isDisabled } = props
  // We ignore the onClick generated by react-aria because the fields that use this component do their own click value
  // computations. We should probably get rid of using react-aria for this but we use it for now still because it
  // makes the right accessibility associations for the labels and values.
  const {
    buttonProps: { onClick: _onClick, ...restButtonProps }
  } = useButton({ ...props }, ref)

  return (
    // @ts-ignore: grommet button uses anchorType & buttonType; buttonProps from aria is just buttonType
    <IncrementOrDecrementButton
      {...restButtonProps}
      size="small"
      disableTooltip
      css={`
        top: ${(props: any) => (props.label === 'Decrement' ? '36px' : '-4px')};
      `}
      label={props.label}
      icon={props.icon}
      isDisabled={isDisabled}
    />
  )
}

const NumberInput = styled.input<{ isDisabled: boolean | undefined }>`
  text-align: center;
  border: none;
  padding-right: 0;
  height: inherit;
  width: 22px;
  color: ${({ isDisabled }) => (isDisabled ? themeColor('text-disabled') : 'inherit')};
  background-color: inherit;
  /* Note: this overrides FieldBox(form-field-components) input[type='number'] padding-left */
  &[type='number'] {
    ${FieldBox} & {
      padding-left: 2px !important;
    }
  }

  :focus {
    outline: none;
  }

  // this set of css is required to remove the native up/down spinners cross-browser since we are creating our  own buttons.
  appearance: textfield;
  -moz-appearance: textfield;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const IncrementOrDecrementButton = styled(IconButton)<{ isDisabled?: boolean }>`
  position: absolute;
  opacity: 0;
  pointer-events: 'none';
  :hover & {
    opacity: 1;
    pointer-events: 'auto';
  }
  ${NumberPicker}:hover & {
    opacity: 1;
    pointer-events: auto;
  }
`
