import { Text } from 'slate'
import { jsx } from 'slate-hyperscript'

import { Align, CustomElement, CustomText, ImageElement, LinkElement } from '../text-editor-types'

export const serialize = (node: CustomElement | CustomText): string => {
  if (Text.isText(node)) {
    let string = node.text
    let { text: _, ...attrs } = node

    Object.keys(attrs).forEach(key => {
      if (key === 'bold') {
        string = `<strong>${string}</strong>`
      } else if (key === 'italic') {
        string = `<em>${string}</em>`
      } else if (key === 'underline') {
        string = `<u>${string}</u>`
      }
    })
    return string
  }

  const children = node.children.map(n => serialize(n)).join('')
  const style = node.align ? ` style="text-align: ${node.align};"` : ''

  switch (node.type) {
    case 'link':
      return `<a href="${(node as LinkElement).url}">${children}</a>`
    case 'quote':
      return `<blockquote${style}>${children}</blockquote>`
    case 'heading-one':
      return `<h1${style}>${children}</h1>`
    case 'heading-two':
      return `<h2${style}>${children}</h2>`
    case 'heading-three':
      return `<h3${style}>${children}</h3>`
    case 'heading-four':
      return `<h4${style}>${children}</h4>`
    case 'heading-five':
      return `<h5${style}>${children}</h5>`
    case 'heading-six':
      return `<h6${style}>${children}</h6>`
    case 'image':
      return `<img src="${(node as ImageElement).src}" />`
    case 'list-item':
      return `<li${style}>${children}</li>`
    case 'numbered-list':
      return `<ol${style}>${children}</ol>`
    case 'paragraph':
      return `<p${style}>${children}</p>`
    case 'code':
      return `<pre>${children}</pre>`
    case 'bulleted-list':
      return `<ul${style}>${children}</ul>`
    case 'table':
      return `<table>${children}</table>`
    case 'table-header':
      return `<thead>${children}</thead>`
    case 'table-header-cell':
      return `<th${style}>${children}</th>`
    case 'table-body':
      return `<tbody>${children}</tbody>`
    case 'table-row':
      return `<tr>${children}</tr>`
    case 'table-cell':
      return `<td${style}>${children}</td>`
    default:
      return children
  }
}

const ELEMENT_TAGS: { [x: string]: (el: HTMLElement) => Object } = {
  A: el => ({ type: 'link', url: el.getAttribute('href') }),
  BLOCKQUOTE: () => ({ type: 'quote' }),
  H1: () => ({ type: 'heading-one' }),
  H2: () => ({ type: 'heading-two' }),
  H3: () => ({ type: 'heading-three' }),
  H4: () => ({ type: 'heading-four' }),
  H5: () => ({ type: 'heading-five' }),
  H6: () => ({ type: 'heading-six' }),
  IMG: el => ({ type: 'image', src: el.getAttribute('src') }),
  LI: () => ({ type: 'list-item' }),
  OL: () => ({ type: 'numbered-list' }),
  P: () => ({ type: 'paragraph' }),
  PRE: () => ({ type: 'code' }),
  UL: () => ({ type: 'bulleted-list' }),
  TABLE: () => ({ type: 'table' }),
  THEAD: () => ({ type: 'table-header' }),
  TH: () => ({ type: 'table-header-cell' }),
  TBODY: () => ({ type: 'table-body' }),
  TR: () => ({ type: 'table-row' }),
  TD: () => ({ type: 'table-cell' })
}

const TEXT_TAGS: { [x: string]: (el: HTMLElement) => Object } = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true })
}

export const deserializeHtml = (html: string) => {
  const normalizedHtml = html?.trim().length === 0 ? '<p></p>' : html
  const document = new DOMParser().parseFromString(normalizedHtml, 'text/html')
  return deserialize(document.body)
}

export const deserialize = (el: HTMLElement): Object | null => {
  if (el.nodeType === Node.TEXT_NODE) {
    if (el.parentNode?.nodeName === 'BODY') {
      return jsx('element', { type: 'paragraph' }, [jsx('text', el.textContent as string)])
    }
    return el.textContent
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null
  } else if (el.nodeName === 'BR') {
    return '\n'
  }

  const { nodeName } = el
  let parent = el

  if (nodeName === 'PRE' && el.childNodes[0] && el.childNodes[0].nodeName === 'CODE') {
    parent = el.childNodes[0] as HTMLElement
  }
  let children = Array.from(parent.childNodes)
    .map(node => deserialize(node as HTMLElement))
    .flat()

  if (
    (nodeName === 'UL' || nodeName === 'OL') &&
    parent.parentNode?.nodeName === 'BODY' &&
    parent.parentNode.childNodes[0].isEqualNode(parent)
  ) {
    return jsx('fragment', {}, children)
  }

  if (children.length === 0) {
    children = [{ text: '' }]
  }

  if (nodeName === 'BODY') {
    return jsx('fragment', {}, children)
  }

  if (ELEMENT_TAGS[nodeName]) {
    const attrs = ELEMENT_TAGS[nodeName](el) as CustomElement
    attrs.align = el.style.textAlign as Align
    return jsx('element', attrs, children)
  }

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el)
    return children.map(child => jsx('text', attrs, child))
  }

  return children
}
