import { useEffect, useMemo } from 'react'

import { Banner, BannerProps, MessageType, useNotify } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { useBannerLayout } from 'main/components/layout/layout-hooks'
import { createNewRunbookVersion } from 'main/services/queries/use-runbook-versions'
import { useNavigateToCurrentVersionAlias } from 'main/recoil/shared/nav-utils'
import { ActiveRunbookVersionModel, ActiveRunModel, RunbookViewModel } from 'main/data-access'

export const RunbookBanner = () => {
  const {
    stage,
    approval_status: approvalStatus,
    id,
    runbook_id: runbookId,
    is_current: isCurrent
  } = ActiveRunbookVersionModel.useGet()
  const run = ActiveRunModel.useGet()

  const processRunbookVersionCreateResponse = ActiveRunbookVersionModel.useOnAction('create')
  const canCreateRunbookVersion = ActiveRunbookVersionModel.useCan('create')

  const canResumeRun = ActiveRunModel.useCan('resume')
  const navigateToCurrentVersion = useNavigateToCurrentVersionAlias()

  const openModal = RunbookViewModel.useAction('modal:open')
  const { showBannerLayout, hideBannerLayout } = useBannerLayout()
  const { t } = useLanguage('runbook', { keyPrefix: 'banner' })
  const notify = useNotify()
  const runType = run?.run_type

  const handleReturnToPlanning = async () => {
    const response = await createNewRunbookVersion(runbookId, { base_version_id: id })
    processRunbookVersionCreateResponse(response)
    notify.success(t('versionSaved'))
  }

  const banner: BannerProps | undefined = useMemo(():
    | { message: string; type: MessageType; linkText?: string; icon: boolean; onClick?: any }
    | undefined => {
    if (!isCurrent) {
      return {
        message: t('message.oldVersion'),
        type: 'idea',
        linkText: t('linkText.goToCurrentVersion'),
        onClick: () => navigateToCurrentVersion(),
        icon: false
      }
    }
    if (approvalStatus === 'pending') {
      return {
        message: t('message.draft'),
        type: 'idea',
        linkText: t('linkText.draft'),
        onClick: () => {
          console.log('approve template')
        },
        icon: false
      }
    }
    switch (stage) {
      case 'complete':
        if (runType === 'live') {
          return {
            message: t('message.complete'),
            type: 'idea',
            icon: false
          }
        }
        if (runType === 'rehearsal') {
          return {
            message: t('message.rehearsalComplete'),
            type: 'info',
            linkText: canCreateRunbookVersion ? t('linkText.rehearsalComplete') : undefined,
            onClick: canCreateRunbookVersion ? handleReturnToPlanning : undefined,
            icon: false
          }
        }
      case 'cancelled':
        if (runType === 'live') {
          return {
            message: t('message.cancelled'),
            type: 'error',
            icon: true
          }
        }
      case 'paused':
        return {
          message: t('message.paused'),
          type: 'info',
          linkText: !!canResumeRun ? t('linkText.paused') : undefined,
          onClick: () => {
            openModal({ type: 'run-resume' })
          },
          icon: false
        }
      default:
        undefined
    }
  }, [
    stage,
    runType,
    approvalStatus,
    t,
    openModal,
    canCreateRunbookVersion,
    handleReturnToPlanning,
    navigateToCurrentVersion
  ])

  useEffect(() => {
    // Reduces height of InnerContent so there is space for banner below
    if (banner) {
      showBannerLayout()
    } else {
      hideBannerLayout()
    }
    return () => {
      hideBannerLayout()
    }
  }, [banner])

  return (
    <>
      {banner ? (
        <Banner
          message={banner.message}
          type={banner.type}
          linkText={banner.linkText}
          icon={banner.icon}
          onClick={banner.onClick}
        />
      ) : undefined}
    </>
  )
}
