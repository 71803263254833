import { useMutation } from 'react-query'

import {
  ChangeRequest,
  ChangeRequestCreatePayload
} from 'main/components/runbook/pages/task-list-ng-connector/change-requests/change-request-types'
import { apiClient } from 'main/services/api/api-client'

export function useChangeRequestCreate() {
  return useMutation(async (payload: ChangeRequestCreatePayload) => {
    const { data } = await apiClient.post<ChangeRequestCreatePayload, ChangeRequest[]>({
      url: `runbooks/${payload.runbook_id}/change_requests`,
      responseProperty: 'change_requests',
      data: payload
    })

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return data!
  })
}
