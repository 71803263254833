import { useEffect, useState } from 'react'
import { eventManager } from 'event-manager'
import { isEqual } from 'lodash'
import { ErrorBoundary } from 'react-error-boundary'

import { Box, Message } from '@cutover/react-ui'
import { TeamDetails } from './team-details'
import { Permissions, Runbook, SelectedTeam, SelectedUser, Stream, ValidationRunbookTeam } from './types'
import { UserDetails } from './user-details'
import { UsersAndTeams } from './users-and-teams'
import { AddUsersTeamsModal } from 'main/components/runbook/modals/add-users-teams/add-users-teams-modal'
import { useLanguage } from 'main/services/hooks'
import { ConfigModel } from 'main/data-access'

export type PeoplePanelProps = {
  accountId: number
  runbook: Runbook
  runbookVersionId: number
  permissions: Permissions
  selectedTab?: string | null
  onTabSelect: (id: string) => void
  runbookTeams: ValidationRunbookTeam[]
  streams: Stream[]
  screen?: PeoplePanelScreen
  selectedUser?: SelectedUser
  selectedTeam?: SelectedTeam
  onClose?: any
}
export type PeoplePanelScreen = 'users-and-teams' | 'user-details' | 'team-details'

type PeoplePanelState = {
  screen: PeoplePanelScreen
  selectedUser: SelectedUser | undefined
  selectedTeam: SelectedTeam | undefined
  runbookTeams: ValidationRunbookTeam[]
}

export function PeoplePanel({
  accountId,
  runbook,
  runbookVersionId,
  permissions,
  selectedTab,
  onTabSelect,
  runbookTeams,
  screen = 'users-and-teams',
  selectedUser,
  selectedTeam,
  streams,
  onClose
}: PeoplePanelProps) {
  let content = null

  const [state, setState] = useState<PeoplePanelState>({
    screen,
    selectedUser,
    selectedTeam,
    runbookTeams
  })
  const [isAddUsersTeamsModalOpen, setIsAddUsersTeamsModalOpen] = useState(false)
  /*
   * We are using this state to disable the modal's focus lock
   * so that items within the modal's inner content are draggable (in this case,
   * the UserFormItem within AddUsersTeamsModal => SelectedUsersTeamsForm).
   */
  const [disableModalFocusLock, setDisableModalFocusLock] = useState(false)

  useEffect(() => {
    if (
      screen !== state.screen ||
      !isEqual(selectedUser, state.selectedUser) ||
      !isEqual(selectedTeam, state.selectedTeam)
    ) {
      setState(prevState => ({
        ...prevState,
        selectedUser,
        selectedTeam,
        screen
      }))
    }
  }, [screen, selectedUser, selectedTeam])

  const setRunbookTeams = (newRunbookTeams: ValidationRunbookTeam[]) =>
    setState(prevState => ({
      ...prevState,
      runbookTeams: newRunbookTeams
    }))

  useEffect(() => {
    setRunbookTeams(runbookTeams)
  }, [runbookTeams])

  const closeAddUsersTeamsModal = () => {
    setIsAddUsersTeamsModalOpen(false)
  }

  const openAddUsersTeamsModal = () => {
    setIsAddUsersTeamsModalOpen(true)
  }

  const { translate } = useLanguage()

  const isReactRunbookEnabled = ConfigModel.useIsFeatureEnabled('react_runbook')

  const handleClose = () => {
    if (isReactRunbookEnabled) {
      onClose?.()
    }

    eventManager.emit('close-runbook-people-panel')
  }

  switch (state.screen) {
    case 'users-and-teams':
      content = (
        <UsersAndTeams
          runbook={runbook}
          runbookVersionId={runbookVersionId}
          permissions={permissions}
          selectedTab={selectedTab}
          onClose={handleClose}
          onTabSelect={id => {
            setState(prevState => ({
              ...prevState,
              selectedTeam: undefined,
              selectedUser: undefined
            }))
            onTabSelect(id)
          }}
          selectedUser={state.selectedUser}
          onUserSelect={user =>
            setState(prevState => ({
              ...prevState,
              screen: 'user-details',
              selectedUser: user,
              selectedTeam: undefined
            }))
          }
          selectedTeam={state.selectedTeam}
          onTeamSelect={team =>
            setState(prevState => ({
              ...prevState,
              screen: 'team-details',
              selectedUser: undefined,
              selectedTeam: team
            }))
          }
          openAddUsersTeamsModal={openAddUsersTeamsModal}
          streams={streams}
        />
      )
      break

    case 'user-details':
      if (state.selectedUser?.id == null) {
        throw new Error('No user selected.')
      }
      content = (
        <UserDetails
          runbookId={runbook.id}
          runbookVersionId={runbookVersionId}
          userId={state.selectedUser.id}
          streams={streams}
          templateType={runbook.templateType}
          onClose={handleClose}
          onBack={() =>
            setState(prevState => ({
              ...prevState,
              selectedTeam: undefined,
              selectedUser: undefined,
              screen: 'users-and-teams'
            }))
          }
        />
      )
      break

    case 'team-details':
      if (state.selectedTeam?.id == null) {
        throw new Error('No team selected.')
      }

      content = (
        <TeamDetails
          accountId={accountId}
          runbookId={runbook.id}
          runbookVersionId={runbookVersionId}
          team={state.selectedTeam}
          onClose={handleClose}
          runbookTeams={state.runbookTeams}
          setRunbookTeams={setRunbookTeams}
          permissions={permissions}
          onBack={() =>
            setState(prevState => ({
              ...prevState,
              selectedTeam: undefined,
              selectedUser: undefined,
              screen: 'users-and-teams'
            }))
          }
          bypassNotification={runbook.isIncident}
        />
      )
      break

    default:
      throw new Error(`Unknown people state '${state.screen}'.`)
  }

  return (
    <Box height="100%">
      <ErrorBoundary
        FallbackComponent={() => <Message type="error" message={[translate('runbook:peoplePanel:error')]} />}
      >
        {content}
      </ErrorBoundary>
      {isAddUsersTeamsModalOpen ? (
        <AddUsersTeamsModal
          isOpen={isAddUsersTeamsModalOpen}
          accountId={accountId}
          closeModal={closeAddUsersTeamsModal}
          runbookId={runbook.id}
          runbookVersionId={runbookVersionId}
          runbookTeams={state.runbookTeams}
          setRunbookTeams={setRunbookTeams}
          streams={streams}
          templateType={runbook.templateType}
          setDisableModalFocusLock={setDisableModalFocusLock}
          disableFocusLock={disableModalFocusLock}
          bypassNotification={runbook.isIncident}
        />
      ) : null}
    </Box>
  )
}
