import { useMemo } from 'react'
import { Modifier, usePopper } from 'react-popper'

type UseSelectPopperOptions = {
  hasIcon?: boolean
  width?: number
}

export const useSelectPopper = (
  disclosureRef: HTMLElement | null,
  popoverRef: HTMLElement | null,
  options: UseSelectPopperOptions = {}
) => {
  const hasIcon = !!options.hasIcon
  const fieldWidth = options.width ?? 0
  const modifiers: Modifier<string, Record<string, unknown>>[] = useMemo(
    () => [
      {
        name: 'sameWidth',
        enabled: true,
        phase: 'beforeWrite' as const,
        requires: ['computeStyles'],
        fn({ state }) {
          // TODO: Add in logic computing width based on whether there is a start icon or not and the width the the end components
          state.styles.popper.minWidth = `${fieldWidth || state.rects.reference.width}px`
          state.styles.popper.maxWidth = `${fieldWidth || state.rects.reference.width}px`
        }
      },
      {
        name: 'offset',
        options: {
          // TODO: adjust the offset based on whether there is an icon
          offset: [hasIcon ? -28 : 0, 4]
        }
      }
    ],
    [hasIcon, fieldWidth]
  )

  return usePopper(disclosureRef, popoverRef, {
    placement: 'bottom-start',
    modifiers
  })
}
