import { Controller, FieldValues, useFormContext } from 'react-hook-form'
import { SetRequired } from 'type-fest'

import { Select, SelectProps } from '@cutover/react-ui'
import { FieldProps, getInputProps } from './form-fields'
import { fetchRunbooks, UseRunbookParams } from 'main/services/queries/use-runbooks'
import { RunbookListRunbook } from 'main/services/queries/types'

const DEFAULT_LIMIT = 10

type RunbookSelectProps<TRunbookType extends Record<any, any>> = Omit<
  SelectProps<TRunbookType>,
  'renderOption' | 'labelKey' | 'valueKey' | 'filterKeys' | 'options'
>
type OptionType = SetRequired<Partial<RunbookListRunbook>, 'id' | 'name'>

export type RunbookFieldProps<TFieldValues extends FieldValues, TRunbookOption extends OptionType = OptionType> = Omit<
  FieldProps<RunbookSelectProps<OptionType>, TFieldValues>,
  'onChange' | 'defaultValue' | 'defaultOptions'
> & {
  defaultOptions?: TRunbookOption[]
  initialValue?: number
  params?: UseRunbookParams
  onChange?: (option: TRunbookOption) => void
  accountSlug: string
}

export function RunbookSelectField<TFieldValues extends FieldValues, TRunbookOption extends OptionType = OptionType>({
  params = { limit: DEFAULT_LIMIT },
  ...props
}: RunbookFieldProps<TFieldValues, TRunbookOption>) {
  const formContext = useFormContext<TFieldValues>()
  const inputProps = getInputProps<TFieldValues>({ ...props, formContext })
  const { control } = formContext

  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <>
            {/* @ts-ignore */}
            <Select<TRunbookOption>
              {...props}
              {...inputProps}
              labelKey="name"
              icon="search"
              valueKey="id"
              minChars={2}
              // @ts-ignore
              loadOptions={async input => {
                const resp = await fetchRunbooks({ ...params, accountId: props.accountSlug, q: input })
                return resp.runbooks
              }}
              defaultValue={value}
              onChange={onChange}
            />
          </>
        )
      }}
    />
  )
}
