import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Box, CheckboxesField, DayTimePicker, Message, Text } from '@cutover/react-ui'
import { CustomFieldsGroupsForm } from 'main/components/shared/custom-field-form/custom-field-groups-form'
import { DuplicateRunbookFormSchema } from '../duplicate-runbooks-modal'
import {
  CheckboxFieldControlled,
  DateTimePickerField,
  RadioboxGroupField,
  TextInputField
} from 'main/components/shared/form'
import { FolderSelectField } from 'main/components/shared/form/folder-select'
import { useLanguage } from 'main/services/hooks'
import {
  CustomField,
  CustomFieldGroup,
  CustomFieldUser,
  RunbookListRunbook,
  RunbookShowRunbook
} from 'main/services/queries/types'
import { PermittedProject } from 'main/services/queries/use-permitted-resources'

type DuplicateRunbookFormProps = {
  context: 'runbook' | 'template' | 'snippet'
  isBulkDuplicate: boolean
  isSnippet: boolean
  isSingleRunbook: boolean
  displayCustomFields: boolean
  customFields?: CustomField[]
  groupedCustomFields?: Record<number, CustomField[]>
  customFieldGroupsLookup?: Record<number, CustomFieldGroup>
  customFieldUsers?: CustomFieldUser[]
  hasPermissionOnExistingProject?: boolean
  projects?: PermittedProject[]
  runbook?: RunbookListRunbook | RunbookShowRunbook
  setLoading: Dispatch<SetStateAction<boolean>>
  showTemplateFolderLockNote?: boolean
}

export const DuplicateRunbookForm = ({
  context,
  isBulkDuplicate,
  isSnippet,
  isSingleRunbook,
  displayCustomFields,
  customFields = [],
  groupedCustomFields = {},
  customFieldGroupsLookup = {},
  customFieldUsers = [],
  hasPermissionOnExistingProject,
  projects,
  runbook,
  setLoading,
  showTemplateFolderLockNote = false
}: DuplicateRunbookFormProps) => {
  const { watch, setValue } = useFormContext<DuplicateRunbookFormSchema>()
  const lockDestinationProject = runbook?.settings_lock_template_copies_to_folder ?? false

  const currentStep = watch('_step')

  useEffect(() => {
    if (projects && !isSnippet) {
      hasPermissionOnExistingProject
        ? setValue('project_modify_type', 'existing')
        : setValue('project_modify_type', 'choose')

      setValue('project_id', runbook?.project_id || projects?.[0].id)
      setLoading(false)
    }
  }, [projects])

  switch (currentStep) {
    case 1:
      return (
        <Step1
          context={context}
          isBulkDuplicate={isBulkDuplicate}
          isSnippet={isSnippet}
          isSingleRunbook={isSingleRunbook}
          displayCustomFields={displayCustomFields}
          customFields={customFields}
          groupedCustomFields={groupedCustomFields}
          customFieldGroupsLookup={customFieldGroupsLookup}
          customFieldUsers={customFieldUsers}
          hasPermissionOnExistingProject={hasPermissionOnExistingProject}
          projects={projects}
          lockDestinationProject={lockDestinationProject}
          showTemplateFolderLockNote={showTemplateFolderLockNote}
        />
      )
    case 2:
      return <Step2 />
    default:
      return null
  }
}

const Step1 = ({
  context,
  isBulkDuplicate,
  isSnippet,
  isSingleRunbook,
  displayCustomFields,
  customFields,
  groupedCustomFields,
  customFieldGroupsLookup,
  customFieldUsers,
  hasPermissionOnExistingProject,
  projects,
  lockDestinationProject,
  showTemplateFolderLockNote
}: {
  context: 'runbook' | 'template' | 'snippet'
  isBulkDuplicate: boolean
  isSnippet: boolean
  isSingleRunbook: boolean
  displayCustomFields: boolean
  customFields?: CustomField[]
  groupedCustomFields?: Record<number, CustomField[]>
  customFieldGroupsLookup?: Record<number, CustomFieldGroup>
  customFieldUsers?: CustomFieldUser[]
  hasPermissionOnExistingProject?: boolean
  projects?: PermittedProject[]
  lockDestinationProject: boolean
  showTemplateFolderLockNote: boolean
}) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'duplicateRunbookModal' })
  const {
    setValue,
    watch,
    clearErrors,
    formState: { errors }
  } = useFormContext<DuplicateRunbookFormSchema>()

  const copyTeamsOption = watch('copy_teams')
  const copyUsersOption = watch('copy_users')
  const projectModifyTypeOption = watch('project_modify_type')

  useEffect(() => {
    if (!copyTeamsOption) {
      setValue('copy_users', false)
    }
  }, [copyTeamsOption])

  useEffect(() => {
    if (copyUsersOption) {
      setValue('copy_teams', true)
    }
  }, [copyUsersOption])

  useEffect(() => {
    if (projectModifyTypeOption === 'existing') {
      clearErrors('project_id')
    }
  }, [projectModifyTypeOption])

  const chkboxesGroupProps = {
    direction: 'row' as const,
    label: t('fields.duplicateOptions.label')
  }

  return (
    <Box direction="column">
      {!isBulkDuplicate && (
        <TextInputField<DuplicateRunbookFormSchema> name="name" label={t('fields.name.label')} autoFocus required />
      )}

      {!isSnippet && !isSingleRunbook && hasPermissionOnExistingProject && (
        <RadioboxGroupField<DuplicateRunbookFormSchema>
          name="project_modify_type"
          label={t('fields.folder.label')}
          direction="row"
          options={[
            { label: t('fields.folder.existing'), value: 'existing' },
            { label: t('fields.folder.choose'), value: 'choose' }
          ]}
        />
      )}

      {(isSingleRunbook || projectModifyTypeOption === 'choose') && !isSnippet && (
        <FolderSelect
          label={t('fields.destinationFolder.label')}
          projects={projects}
          loading={!projects}
          disabled={lockDestinationProject}
        />
      )}

      {isBulkDuplicate && !isSnippet && showTemplateFolderLockNote && projectModifyTypeOption === 'choose' && (
        <Box style={{ marginBottom: '20px' }}>
          <Message message={t('fields.destinationFolder.lockNote')} type="info" />
        </Box>
      )}

      {isBulkDuplicate && (
        <TextInputField<DuplicateRunbookFormSchema>
          name="suffix"
          label={t('fields.suffix.label')}
          tooltipText={t('fields.suffix.helpText', { context: context })}
        />
      )}

      <CheckboxesField {...chkboxesGroupProps}>
        <CheckboxFieldControlled<DuplicateRunbookFormSchema>
          name="copy_tasks"
          label={t('fields.duplicateOptions.tasksStreams')}
        />
        <CheckboxFieldControlled<DuplicateRunbookFormSchema>
          name="copy_teams"
          label={t('fields.duplicateOptions.teams')}
        />
        <CheckboxFieldControlled<DuplicateRunbookFormSchema>
          name="copy_users"
          label={t('fields.duplicateOptions.runbookUsers')}
        />
      </CheckboxesField>
      {displayCustomFields && (
        <CustomFieldsGroupsForm
          customFields={customFields}
          groupedCustomFields={groupedCustomFields}
          customFieldGroupsLookup={customFieldGroupsLookup}
          customFieldUsers={customFieldUsers}
          errors={errors}
        />
      )}
    </Box>
  )
}

const FolderSelect = ({
  projects,
  ...props
}: {
  label: string
  projects?: PermittedProject[]
  loading?: boolean
  disabled?: boolean
}) => {
  return (
    <FolderSelectField<DuplicateRunbookFormSchema>
      {...props}
      folders={projects ?? []}
      name={'project_id'}
      clearable={false}
      required
    />
  )
}

const Step2 = () => {
  const { control, setValue, watch, clearErrors } = useFormContext<DuplicateRunbookFormSchema>()
  const { t } = useLanguage('runbooks', { keyPrefix: 'duplicateRunbookModal' })
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false)
  const timingModeOption = watch('timing_mode')

  const initialStartPlanned = (): Date => {
    const date = new Date()
    date.setHours(0, 0, 0, 0)
    return date
  }

  useEffect(() => {
    if (timingModeOption === 'unscheduled') {
      clearErrors('start_scheduled')

      setValue('start_planned', initialStartPlanned(), {
        shouldValidate: true,
        shouldTouch: true
      })
    }
  }, [timingModeOption])

  return (
    <Box direction="column">
      <RadioboxGroupField<DuplicateRunbookFormSchema>
        name="timing_mode"
        label={t('fields.timingMode.label')}
        direction="row"
        options={[
          { label: t('fields.timingMode.unscheduled'), value: 'unscheduled' },
          { label: t('fields.timingMode.scheduled'), value: 'scheduled' }
        ]}
      />
      {timingModeOption === 'scheduled' && (
        <DateTimePickerField<DuplicateRunbookFormSchema>
          name="start_scheduled"
          label={t('fields.startScheduled.label')}
          required
          fixed
          fixedWidth
        />
      )}
      <Text
        size="small"
        css="text-decoration-line: underline; cursor: pointer; margin-bottom: 10px"
        role="button"
        onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
        tabIndex={0}
        onKeyDown={e => {
          if (e.code === 'Enter') {
            setShowAdvancedOptions(!showAdvancedOptions)
          }
        }}
      >
        {showAdvancedOptions ? t('advancedOptions', { action: 'Hide' }) : t('advancedOptions', { action: 'Show' })}
      </Text>
      {showAdvancedOptions && (
        <>
          {timingModeOption === 'scheduled' ? (
            <DateTimePickerField<DuplicateRunbookFormSchema>
              name="end_scheduled"
              label={t('fields.endScheduled.label')}
              fixed
              fixedWidth
            />
          ) : (
            <Controller
              name={'start_planned'}
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <DayTimePicker
                    dayZeroStartValue={value ? new Date(value) : new Date()}
                    onChange={date => (!!date ? onChange(date.toISOString()) : onChange(null))}
                    disableDayPickerOnly
                    value={!!value ? new Date(value) : new Date()}
                  />
                )
              }}
            />
          )}
          <CheckboxesField label={t('fields.shiftTime.label')}>
            <CheckboxFieldControlled<DuplicateRunbookFormSchema>
              name="shift_time"
              label={t('fields.shiftTime.helpText')}
            />
          </CheckboxesField>
        </>
      )}
    </Box>
  )
}
