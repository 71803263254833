import { useState } from 'react'
import { eventManager } from 'event-manager'
import { useQueryClient } from 'react-query'

import { LoadingPanel } from '@cutover/react-ui'
import { RunbookDashboardScheduleForm } from './runbook-dashboard-schedule-form'
import { RunbookDashboardScheduleList } from './runbook-dashboard-schedule-list'
import { UpdateUserSettingParams, UserSettingsResponse } from './runbook-dashboard-schedule-types'
import { ShareRunbookDashboardModal } from '../runbook-dashboard/share-runbook-dashboard-modal'
import { useUpdateEmailScheduleUserSetting } from 'main/services/queries/use-email-schedule-user-setting'
import { useRunbookDashboardSchedulesQuery } from 'main/services/queries/use-email-schedule-user-settings'

type DashboardScheduleParams = {
  accountId: string
  runbookId: number
  runbookVersionId: number
  currentUserId: number
  dashboardId: number
  timezone: string
  taskIds?: number[]
  isFiltered: boolean
}

export const RunbookDashboardSchedulePanel = ({
  accountId,
  runbookId,
  runbookVersionId,
  currentUserId,
  dashboardId,
  timezone,
  isFiltered
}: DashboardScheduleParams) => {
  const client = useQueryClient()
  const [editSettingId, setEditSettingId] = useState<number | null>(null)
  const { data, isLoading } = useRunbookDashboardSchedulesQuery({ runbookId, dashboardId })
  const updateSchedule = useUpdateEmailScheduleUserSetting()
  const [addModalOpen, setAddModalOpen] = useState(false)

  const handleClose = () => {
    eventManager.emit('close-runbook-dashboard-schedule')
  }

  const handleUpdate = (setting: UpdateUserSettingParams) => {
    updateSchedule.mutate(setting, {
      onSuccess: response => {
        client.setQueriesData<UserSettingsResponse | undefined>(['user_settings', runbookId, dashboardId], cache => {
          if (!cache || !response?.data?.user_setting) return

          const cacheIndex = cache.user_settings.findIndex(setting => setting.id === response?.data?.user_setting.id)
          cache.user_settings.splice(cacheIndex, 1, {
            ...response.data.user_setting
          })
          return cache
        })
      }
    })
  }

  const handleAddModalOpen = () => {
    setAddModalOpen(true)
  }

  const handleAddModalClose = () => {
    setAddModalOpen(false)
  }

  const editSetting = data?.user_settings.find(userSetting => userSetting.id === editSettingId)

  return (
    <>
      {isLoading && <LoadingPanel />}
      {editSetting && data && (
        <RunbookDashboardScheduleForm
          onBack={() => setEditSettingId(null)}
          runbookId={runbookId}
          userId={currentUserId}
          runbookVersionId={runbookVersionId}
          setting={editSetting}
          onSubmit={handleUpdate}
          onClose={handleClose}
        />
      )}
      {data && !editSetting && (
        <RunbookDashboardScheduleList
          onClose={handleClose}
          data={data}
          onScheduleClick={(_, setting) => setEditSettingId(setting.id)}
          onAdd={handleAddModalOpen}
        />
      )}
      {addModalOpen && (
        <ShareRunbookDashboardModal
          onClose={handleAddModalClose}
          dashboardId={dashboardId}
          runbookId={runbookId}
          runbookVersionId={runbookVersionId}
          accountId={parseInt(accountId, 10)}
          currentUserId={currentUserId}
          filters={{
            account_id: accountId,
            runbook_id: runbookId,
            runbook_version_id: runbookVersionId,
            dashboard_id: dashboardId
          }}
          isFiltered={isFiltered}
          timezone={timezone}
          advancedOpen={true}
        />
      )}
    </>
  )
}
