import { useState } from 'react'

import { IconButton, Message, Modal, Text, TextInput } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { ActivityVM, useDeleteCommentActivityMutation } from 'main/services/queries/use-activities'

type RemoveCommentControlProps = {
  activity: ActivityVM
}

export const RemoveCommentControl = ({ activity }: RemoveCommentControlProps) => {
  const { t } = useLanguage('activities')
  const deleteCommentMutation = useDeleteCommentActivityMutation()
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false)
  const [deletionReason, setDeletionReason] = useState<string>('')
  const [showError, setShowError] = useState<boolean>(false)

  const handleOnModalClose = () => {
    setConfirmModalOpen(false)
    setShowError(false)
    setDeletionReason('')
  }

  const deleteComment = async () => {
    if (activity.display?.comment_id) {
      deleteCommentMutation.mutate({ commentId: activity.display.comment_id, reason: deletionReason })
    }
    handleOnModalClose()
  }

  const renderModalContent = () => (
    <>
      {showError && <Message message={t('feed.deleteConfirm.error')} type="error" />}
      <Text>{t('feed.deleteConfirm.text')}</Text>
      <TextInput
        label={t('feed.deleteConfirm.inputLabel')}
        onChange={e => setDeletionReason(e.target.value)}
        hasError={showError}
        required
      />
    </>
  )

  return (
    <>
      <IconButton
        tertiary
        size="medium"
        icon="delete"
        onClick={() => setConfirmModalOpen(true)}
        label={t('feed.deleteComment')}
        tipPlacement="top"
        data-testid={`delete-button-${activity.id}`}
        css="mix-blend-mode: luminosity;" // Allows blending with highlighted background
      />
      <Modal
        data-testid={`confirm-deletion-${activity.id}`}
        open={confirmModalOpen}
        onClickConfirm={() => (!!deletionReason ? deleteComment() : setShowError(true))}
        onClose={() => handleOnModalClose()}
        title={t('feed.deleteConfirm.title')}
        dismissible={true}
        hasCancelButton={true}
        focusConfirmButton={true}
        confirmText={t('feed.deleteConfirm.button')}
        confirmIcon="check"
      >
        {renderModalContent()}
      </Modal>
    </>
  )
}
