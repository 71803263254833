import { unescape } from 'lodash'
import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query'

import { Nullish } from '@cutover/utility-types'
import { queryClient } from 'main/query-client'
import { apiClient } from 'main/services/api/api-client'
import { ApiError } from 'main/services/api/http-gateway-adapter'

export type RunbookTeam = {
  id: number
  name: string
  userIds: number[]
  color: string
  linked: boolean
}

export type RunbookTeamsQuery = {
  runbookId: number
  runbookVersionId: number
  offset: number
  limit: number
  search?: Nullish<string>
}

type PagedRunbookTeams = {
  meta: {
    runbookUsersCount: number
    runbookTeamsCount: number
    filteredRunbookTeamsCount: number
    filteredRunbookUsersCount: number
  }
  runbookTeams: RunbookTeam[]
}

export function useRunbookTeamsQuery(
  query: RunbookTeamsQuery,
  options?: UseInfiniteQueryOptions<PagedRunbookTeams, ApiError>
) {
  const { runbookId, runbookVersionId, offset, limit, search } = query

  const getRunbookTeams = async (offset: number) => {
    const { data } = await apiClient.get<PagedRunbookTeams>({
      url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/runbook_teams`,
      params: {
        offset,
        limit,
        query: search
      },
      // FIXME: convertCase is deprecated
      convertCase: true
    })

    // create usersCount and teamsCount cached data to use for frontend validation
    queryClient.setQueryData(['usersCount', runbookVersionId], data.meta.runbookUsersCount)
    queryClient.setQueryData(['teamsCount', runbookVersionId], data.meta.runbookTeamsCount)

    // TODO: remove HTML entities from server side data store
    return {
      meta: data.meta,
      runbookTeams: (data.runbookTeams ?? []).map(team => ({ ...team, name: unescape(team.name) }))
    }
  }

  // TODO: review staleTime option
  return useInfiniteQuery<PagedRunbookTeams, ApiError>(
    ['feature', 'runbookTeam', runbookId, runbookVersionId, offset, limit, search],
    ({ pageParam = 0 }) => getRunbookTeams(pageParam),
    {
      getNextPageParam: (lastGroup, allPages) => {
        return lastGroup?.meta?.filteredRunbookTeamsCount > allPages.length * limit
          ? allPages.length * limit
          : undefined
      },
      ...(options ?? {})
    }
  )
}
