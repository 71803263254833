import { unescape } from 'lodash'
import { useQuery } from 'react-query'

import { RunbookListRunbook } from './types'
import { apiClient, ApiError } from '../api'

type UseRunbookCopiesProps = {
  runbookId: string | number
}

export const useRunbookCopies = ({ runbookId }: UseRunbookCopiesProps) => {
  return useQuery<RunbookListRunbook[], ApiError>(['runbooks', String(runbookId), 'copies'], async () => {
    const { data } = await apiClient.get<RunbookListRunbook[]>({
      url: `runbooks/${runbookId}/copies`,
      responseProperty: 'runbooks'
    })

    return data.map(runbook => ({
      ...runbook,
      name: unescape(runbook.name),
      project_name: unescape(runbook.project_name)
    }))
  })
}
