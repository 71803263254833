import { useMutation, UseMutationOptions } from 'react-query'

import { apiClient, ApiError } from '../api'
import { RagStatus, Role } from 'main/services/queries/types'

export type RunbooksBulkEditPayload = {
  changes: {
    name?: string
    name_modify_type?: AttributeModifyType
    project_id?: number | null
    status?: RagStatus | null
    status_message?: string
    roles?: Role[]
    role_modify_type?: RoleModifyType
    description?: string
    description_modify_type?: AttributeModifyType
  }
  runbook_ids: number[]
}

export type AttributeModifyType = 'suffix' | 'prefix' | 'replace'
export type RoleModifyType = 'add' // currently this is the only capable role modify type

export const useRunbooksBulkEdit = (options: UseMutationOptions<unknown, ApiError, RunbooksBulkEditPayload> = {}) => {
  return useMutation<unknown, ApiError, RunbooksBulkEditPayload>(
    'prepare-bulk-update',
    async payload => {
      const { data } = await apiClient.patch<RunbooksBulkEditPayload, unknown>({
        url: 'runbooks/bulk_update',
        data: payload
      })
      return data as unknown
    },
    options
  )
}
