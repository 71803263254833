import { ReactElement, Ref, useCallback, useEffect, useRef, useState } from 'react'
import { usePrevious, usePreviousDistinct } from 'react-use'

import { Box, Button, Modal, Text } from '../..'

type SaveEditingFormModalProps = {
  isDirty: boolean
  isError?: boolean
  onReset: () => void
  onSubmit: () => void
  isSubmitting: boolean
  render: ({ ref }: { ref: Ref<HTMLDivElement> }) => ReactElement | null
}

export function SaveEditingFormModal({
  isDirty,
  isError,
  onReset,
  onSubmit,
  isSubmitting,
  render
}: SaveEditingFormModalProps) {
  const ref = useRef<HTMLDivElement>(null)
  const [showModal, setShowModal] = useState(false)
  const [lastTarget, setLastTarget] = useState<{
    element: EventTarget | null
    clientX: number | null
    clientY: number | null
  } | null>(null)

  const handler = useCallback(
    (event: MouseEvent) => {
      const target = event.target as HTMLElement | null
      const dragger = document.getElementById('right-panel-dragger')

      if (
        showModal || // already viewing modal
        !target || // target is typed as possibly null (coerced from EventTarget | null)
        !ref.current || // no right panel ref
        ref.current.contains(target) || // if clicked inside right panel
        target === dragger || // if clicked on dragger
        dragger?.contains(target) // if clicked on drag handle
      ) {
        return
      }

      // TODO: determine what is needed from these removing useClickAway hook
      // Allow click on a confirmation modal and popups
      const noModalOpen =
        !document.body.classList.contains('ReactModal__Body--open') &&
        !document.getElementsByClassName('react-modal-open')[0] &&
        !document.body.classList.contains('ngdialog-open')
      const noPopupOpen = !document.getElementsByClassName('popup-content')[0]
      const reactMenuPortals = document.querySelectorAll('.szh-menu') // TODO: should use our own namespace
      const grommetPortals = document.querySelectorAll('[data-g-portal-id]')
      const rhfDevToolsBoxes = document.querySelectorAll('.rhf-dev-tools-box')
      // Find better selector, being lazy right now
      const downshiftPortals = document.querySelectorAll('[id^="downshift"]')
      const isGrommetPortalClicked = Array.from(grommetPortals).some(portal => portal.contains(target))
      const isReactMenuPortalClicked = Array.from(reactMenuPortals).some(portal => portal.contains(target))
      const isSelectPopperClicked = target?.closest('#select-menu-portal') !== null
      const isDownshiftPortalClicked = Array.from(downshiftPortals).some(portal => portal.contains(target))
      const isRHFDevToolsClicked = Array.from(rhfDevToolsBoxes).some(box => box.contains(target))

      if (
        isDirty &&
        noModalOpen &&
        noPopupOpen &&
        !isGrommetPortalClicked &&
        !isReactMenuPortalClicked &&
        !isSelectPopperClicked &&
        !isDownshiftPortalClicked &&
        !isRHFDevToolsClicked
      ) {
        event.stopPropagation()
        event.preventDefault()

        setLastTarget({
          element: event.target,
          clientX: event.clientX,
          clientY: event.clientY
        })
        setShowModal(true)
      }
    },
    [isDirty, showModal]
  )

  // TODO: pulled this out so can use this for the "continue without saving" functionality that we should be doing vs
  // having a button to discard form changes from a modal that doesn't follow through with the previous action
  const clickContinue = useCallback(() => {
    if (lastTarget) {
      const element = lastTarget.element as HTMLElement

      if (document.contains && element && document.contains(element)) {
        if (element.click) {
          element.click()
        } else {
          ;(element.closest('button') ?? element.closest('a'))?.click()
        }
      }

      setLastTarget(null)
    }
  }, [lastTarget])

  const handleOnReset = () => {
    onReset()
    clickContinue()
    setShowModal(false)
  }

  const handleClickSubmit = () => {
    if (isError) {
      setShowModal(false)
    } else {
      onSubmit()
    }
  }

  // TODO: investigate if both are needed. Keeping now to be safe that all handlers are removed.
  const prevHandler = usePrevious(handler)
  const prevDistinctHandler = usePreviousDistinct(handler)

  useEffect(() => {
    if (prevDistinctHandler) document.removeEventListener('click', prevDistinctHandler, true)
    if (isDirty) document.addEventListener('click', handler, true)

    return () => {
      if (prevDistinctHandler) document.removeEventListener('click', prevDistinctHandler, true)
      if (prevHandler) document.removeEventListener('click', prevHandler, true)

      document.removeEventListener('click', handler, true)
    }
  }, [handler])

  useEffect(() => {
    if (!isDirty && lastTarget) {
      clickContinue()
    }
  }, [isDirty])

  useEffect(() => {
    if (!isSubmitting) {
      setShowModal(false)
    }
  }, [isSubmitting])

  return (
    <>
      {render({ ref })}
      <Modal title="Save your changes" open={showModal} hideFooter onClose={() => setShowModal(false)}>
        <Box gap="large">
          <Text>You have unsaved changes, please save them or discard before continuing.</Text>
          <Box height={{ min: '52px', max: '52px' }} justify="end" gap="xsmall" direction="row">
            <Button
              tertiary
              icon="undo"
              onClick={handleOnReset}
              disabled={isSubmitting && !isError}
              label="Discard Changes"
            />
            <Button
              primary
              icon="arrow-forward"
              onClick={handleClickSubmit}
              disabled={isSubmitting && !isError}
              loading={isSubmitting && !isError}
              label={isSubmitting ? 'Saving' : 'Save'}
            />
          </Box>
        </Box>
      </Modal>
    </>
  )
}
