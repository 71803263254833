import { forwardRef, ReactNode, useState } from 'react'
import { Placement } from '@popperjs/core'
import { useTooltip, useTooltipTrigger } from '@react-aria/tooltip'
import { mergeProps } from '@react-aria/utils'
import { useTooltipTriggerState } from '@react-stately/tooltip'
import { AriaTooltipProps } from '@react-types/tooltip'
import { TipProps } from 'grommet'
import ReactDOM from 'react-dom'
import { usePopper } from 'react-popper'
import styled, { keyframes } from 'styled-components/macro'
import { assignRef, useCallbackRef } from 'use-callback-ref'

import { resolveColor } from '../../theme'

const ARROW_SIZE = 4
const DEFAULT_DELAY = 400
export const TOOLTIP_MAX_WIDTH = 240

export type TooltipProps = AriaTooltipProps & {
  /** Content to display in the tooltip */
  content?: ReactNode | TipProps
  /** A single child which is the trigger for the tooltip */
  children: ReactNode
  placement?: Placement
  hasArrow?: boolean
  isOpen?: boolean
  isDisabled?: boolean
  /** The delay time for the tooltip to show up. [See guidelines](https://spectrum.adobe.com/page/tooltip/#Immediate-or-delayed-appearance). */
  delay?: number | boolean
  width?: number
  // to override the trigger props onClick property (if you are supplying an onClick on an outside div that wraps the entire tooltip)
  disableOnClickAsTriggerProp?: boolean
  // FIXME: problem with typing ref with union and css prop.
  truncateContent?: boolean
  css?: any
}

export const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(function (
  {
    content,
    children,
    placement: desiredPlacement = 'left',
    hasArrow = true,
    isOpen: controlledIsOpen,
    isDisabled,
    delay = DEFAULT_DELAY,
    width,
    disableOnClickAsTriggerProp,
    truncateContent,
    ...restProps
  },
  ref
) {
  // Popper needs to ensure re-renders after dom element updates for the trigger and
  // tooltip elements. A suggested way is to use a setState for a dom element and its update
  // function as the pseudo callback-style ref. however @react-aria needs typed react ref
  // objects or legacy callback refs for the hooks functions. Using `useCallbackRef` we can create
  // a properly typed ref and provide a function to call when a ref changes. In order to force the
  // component to re-render after the dom element ref changes we can increment an internal state value.
  const [, forceUpdateCount] = useState(0)

  const tooltipRef = useCallbackRef<HTMLDivElement>(null, el => {
    // using `mergeRefs` was not possible here because it doesn't result in the callback triggering
    // for the callback ref. In order to keep any forwarded ref for the tooltip, `assignRef` allows
    // for manually assignment.
    assignRef(ref, el)
    forceUpdateCount(count => count + 1)
  })

  const triggerRef = useCallbackRef<HTMLSpanElement>(null, () => {
    forceUpdateCount(count => count + 1)
  })

  const tooltipArrowRef = useCallbackRef<HTMLDivElement>(null, () => {
    forceUpdateCount(count => count + 1)
  })

  const { styles, attributes } = usePopper(triggerRef.current, tooltipRef.current, {
    placement: desiredPlacement,
    modifiers: [
      {
        name: 'arrow',
        options: { element: tooltipArrowRef.current, padding: 10 }
      },
      { name: 'offset', options: { offset: [0, 10] } }
    ]
  })

  const state = useTooltipTriggerState({
    isOpen: controlledIsOpen,
    delay: delay === false ? 0 : delay === true ? undefined : delay,
    isDisabled
  })

  const { tooltipProps } = useTooltip(
    {
      isOpen: controlledIsOpen,
      ...restProps
    },
    state
  )

  const { triggerProps, tooltipProps: triggerTooltipProps } = useTooltipTrigger(
    {
      isOpen: controlledIsOpen,
      isDisabled
    },
    state,
    triggerRef
  )

  if (disableOnClickAsTriggerProp) {
    delete triggerProps.onClick
  }

  return (
    <>
      <span
        {...triggerProps}
        ref={triggerRef}
        style={
          truncateContent
            ? { cursor: 'pointer', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }
            : { cursor: 'pointer' }
        }
      >
        {children}
      </span>
      {state.isOpen &&
        ReactDOM.createPortal(
          <TooltipContainer
            style={styles?.popper || {}}
            ref={tooltipRef}
            {...mergeProps(tooltipProps, triggerTooltipProps, attributes?.popper || {})}
            width={width}
            css={`
              font-weight: 400;
              text-transform: none;
              white-space: normal;
            `}
          >
            <span>
              <>
                {content || ''}
                {hasArrow && <span className="react-ui-tooltip-arrow" ref={tooltipArrowRef} style={styles.arrow} />}
              </>
            </span>
          </TooltipContainer>,
          document.body
        )}
    </>
  )
})

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const TooltipContainer = styled.span<{ width?: number }>`
  background: ${({ theme }) => resolveColor('tooltip-bg', theme)};
  color: ${({ theme }) => resolveColor('tooltip-text', theme)};
  border-radius: 4px;
  font-size: 15px;
  padding: 4px 8px;
  max-width: ${TOOLTIP_MAX_WIDTH}px;
  width: ${({ width }) => (width ? width + 'px' : 'auto')};
  text-align: center;
  line-height: 18px;
  animation: ${fadeIn} 250ms ease-in-out;
  z-index: 1000;
  overflow-wrap: break-word;

  .react-ui-tooltip-arrow {
    &,
    &::before {
      position: absolute;
      width: ${ARROW_SIZE * 2}px;
      height: ${ARROW_SIZE * 2}px;
      display: block;
    }

    background: transparent;

    &::before {
      background-color: ${({ theme }) => resolveColor('tooltip-bg', theme)};
      visibility: visible;
      content: '';
      transform: rotate(45deg);
    }
  }

  &[data-popper-placement^='bottom'] .react-ui-tooltip-arrow {
    top: -${ARROW_SIZE}px;
  }

  &[data-popper-placement^='top'] .react-ui-tooltip-arrow {
    bottom: -${ARROW_SIZE}px;
  }

  &[data-popper-placement^='left'] .react-ui-tooltip-arrow {
    right: -${ARROW_SIZE}px;
  }

  &[data-popper-placement^='right'] .react-ui-tooltip-arrow {
    left: -${ARROW_SIZE}px;
  }
`
