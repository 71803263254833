import { Editor, Node, Path, Point, Element as SlateElement, Transforms } from 'slate'
import { ReactEditor } from 'slate-react'

const createTableNode = (rows: number, columns: number) => {
  return {
    type: 'table',
    children: [createHeaderNode(columns), createBodyNode(rows, columns)]
  }
}

const createHeaderNode = (columns: number) => {
  const headerCellNodes = []
  for (let i = 0; i < columns; i++) {
    headerCellNodes.push(createHeaderCellNode())
  }

  const headerRow = {
    type: 'table-row',
    children: headerCellNodes
  }

  return {
    type: 'table-header',
    children: [headerRow]
  }
}

const createHeaderCellNode = () => {
  return {
    type: 'table-header-cell',
    children: [{ text: '' }]
  }
}

const createBodyNode = (rows: number, columns: number) => {
  const rowNodes = []
  for (let i = 1; i < rows; i++) {
    rowNodes.push(createRowNode(columns))
  }

  return {
    type: 'table-body',
    children: rowNodes
  }
}

const createRowNode = (columns: number) => {
  const cellNodes = []
  for (let i = 0; i < columns; i++) {
    cellNodes.push(createCellNode())
  }

  return {
    type: 'table-row',
    children: cellNodes
  }
}

const createCellNode = () => {
  return {
    type: 'table-cell',
    children: [{ text: '' }]
  }
}

export const addRow = (editor: Editor) => {
  rowOperation(editor, 'add')
}

export const removeRow = (editor: Editor) => {
  rowOperation(editor, 'remove')
}

const rowOperation = (editor: Editor, operation: 'add' | 'remove') => {
  const { selection } = editor
  Transforms.collapse(editor, { edge: 'focus' })
  if (!!selection) {
    const [tableNode] = Editor.nodes(editor, {
      match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'table-row'
    })

    if (tableNode) {
      const [[table]] = Editor.nodes(editor, {
        match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'table'
      })
      const [, currentRow] = tableNode
      const path = currentRow

      if (operation === 'add') {
        Transforms.insertNodes(editor, createRowNode(colLength(table as SlateElement)) as SlateElement, {
          at: path
        })
      } else {
        Transforms.removeNodes(editor, {
          at: path
        })
      }
    }
  }
}

export const addColumn = (editor: Editor) => {
  columnOperation(editor, 'add')
}

export const removeColumn = (editor: Editor) => {
  columnOperation(editor, 'remove')
}

const columnOperation = (editor: Editor, operation: 'add' | 'remove') => {
  const { selection } = editor
  Transforms.collapse(editor, { edge: 'focus' })
  if (!!selection) {
    const [[table]] = Editor.nodes(editor, {
      match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'table'
    })
    const numRows = rowLength(table as SlateElement)
    const hasTableHeader = hasHeaderRow(table as SlateElement)
    const rows = hasTableHeader ? numRows - 1 : numRows

    const [tableCellNode] = Editor.nodes(editor, {
      match: n =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && (n.type === 'table-cell' || n.type === 'table-header-cell')
    })
    if (tableCellNode) {
      const [, currentCell] = tableCellNode
      const startPath = currentCell

      if ((tableCellNode[0] as SlateElement).type === 'table-header-cell') {
        startPath[startPath.length - 3] = 1 // tbody node
      }
      startPath[startPath.length - 2] = 0 // tr node

      for (let row = 0; row < rows; row++) {
        if (operation === 'add') {
          Transforms.insertNodes(editor, createCellNode() as SlateElement, {
            at: startPath
          })
        } else {
          Transforms.removeNodes(editor, {
            at: startPath
          })
        }
        startPath[startPath.length - 2]++
      }

      if (hasTableHeader) {
        startPath[startPath.length - 3] = 0 // thead node
        startPath[startPath.length - 2] = 0 // tr node
        if (operation === 'add') {
          Transforms.insertNodes(editor, createHeaderCellNode() as SlateElement, {
            at: startPath
          })
        } else {
          Transforms.removeNodes(editor, {
            at: startPath
          })
        }
      }
    }
  }
}

const hasHeaderRow = (table: SlateElement): boolean => {
  let result = false
  function walkTable(currentNode: any) {
    if (currentNode.children) {
      for (let node of currentNode.children) {
        if (node.type === 'table-header') {
          result = true
          break
        }
      }
    }
  }
  walkTable(table)
  return result
}

const rowLength = (table: SlateElement) => {
  let rows = 0
  function walkTable(currentNode: any) {
    if (currentNode.children) {
      for (let node of currentNode.children) {
        if (node.type === 'table-row') {
          rows++
        }
        walkTable(node)
      }
    }
  }
  walkTable(table)
  return rows
}

const colLength = (table: SlateElement) => {
  let cols = 0
  function walkTable(currentNode: any) {
    if (currentNode.children) {
      for (let node of currentNode.children) {
        if (node.type === 'table-row') {
          cols = node.children.length
          break
        } else {
          walkTable(node)
        }
      }
    }
  }
  walkTable(table)
  return cols
}

export const insertTable = (editor: Editor) => {
  const { selection } = editor
  const table = createTableNode(3, 3)
  ReactEditor.focus(editor)

  if (!!selection) {
    Transforms.collapse(editor, { edge: 'focus' })
    const [parentNode, parentPath] = Editor.parent(editor, selection.focus?.path)

    if (editor.isVoid(parentNode as SlateElement)) {
      Transforms.insertNodes(editor, table as SlateElement, {
        at: Path.next(parentPath),
        select: true
      })
    } else {
      const [lastNode, lastPath] = Node.last(editor, [])
      const atLastChar =
        Point.compare(selection.anchor, {
          path: lastPath,
          offset: Node.string(lastNode).length
        }) === 0
      if (atLastChar) {
        Transforms.insertNodes(editor, [table, { type: 'paragraph', children: [{ text: '' }] }] as SlateElement[], {
          select: true
        })
      } else {
        Transforms.insertNodes(editor, table as SlateElement)
      }
    }
  } else {
    Transforms.insertNodes(editor, table as SlateElement)
  }
}
