import { useMutation, UseMutationOptions } from 'react-query'

import { apiClient } from '../api'

export type UserToVerify = {
  id: string | undefined
  first_name: string
  last_name: string
  input_email: string
  unique_id: string | undefined
  provider: string | undefined
  country_code: string
  mobile_number: string
  invitation_token: string
  secret_key: string
  password: string
  password_confirmation: string
}

// TODO: type response from UserValidateTokenSerializer
export type UserVerifyResponse = {
  name: string
}

export async function verifyUserByInviteToken(invitationToken: string) {
  const { data } = await apiClient.get<UserToVerify & { email: string }>({
    url: `users/by_invite_token?invitation_token=${invitationToken}`,
    responseProperty: 'user'
  })

  return data
}

export const SSOUserInviteVerification = (user: UserToVerify) => {
  const userSSOParams = {
    secret_key: user.secret_key || '',
    country_code: user.country_code || '',
    mobile_number: user.mobile_number || '',
    invitation_token: user.invitation_token || '',
    unique_id: user.unique_id || ''
  }
  const url = buildAuthUrl(userSSOParams)
  window.location.replace(url)
}

export const useEmailUserInviteVerification = (
  options: UseMutationOptions<UserVerifyResponse, { errors: string[] }, UserToVerify>
) => {
  return useMutation(
    'user_verification',
    async (user: UserToVerify) => {
      const { data } = await apiClient.put<UserToVerify, UserVerifyResponse>({
        url: 'auth/invitation',
        data: user
      })

      return data as UserVerifyResponse
    },
    options
  )
}

const buildAuthUrl = (params: { [x: string]: string }) => {
  let authUrl = `api/saml?auth_origin_url=${encodeURIComponent(window.location.href)}&omniauth_window_type=sameWindow`
  for (let key in params) {
    const val = params[key]
    authUrl += '&'
    authUrl += encodeURIComponent(key)
    authUrl += '='
    authUrl += encodeURIComponent(val)
  }
  return authUrl
}
