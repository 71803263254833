import { useMutation } from 'react-query'

import {
  ChangeRequest,
  ChangeRequestLinkPayload
} from 'main/components/runbook/pages/task-list-ng-connector/change-requests/change-request-types'
import { apiClient } from 'main/services/api/api-client'
import { ApiError } from 'main/services/api/http-gateway-adapter'

interface NestedApiError extends Omit<ApiError, 'errors'> {
  errors: { failures?: string }
}

export const useChangeRequestLink = () => {
  return useMutation<ChangeRequest, NestedApiError, ChangeRequestLinkPayload>(async payload => {
    const response = await apiClient.post<ChangeRequestLinkPayload, ChangeRequest>({
      url: `runbooks/${payload.runbook_id}/change_requests/bulk`,
      responseProperty: 'change_requests',
      data: { change_requests: payload.change_requests, runbook_id: payload.runbook_id }
    })

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return response.data!
  })
}
