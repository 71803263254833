import Cookies from 'js-cookie'
import { useQuery, UseQueryOptions } from 'react-query'

import { FrontendUserSettingType, PermissionsRecordType } from './types'
import { apiClient } from '../api'
import { ApiError } from '../api/http-gateway-adapter'

export type CurrentUser = {
  id: number
  avatar: null
  color: string
  completed_runbooks_count: number
  country_code: string
  created_at: string
  current_sign_in_at: number
  cutover_employee: boolean
  description: string
  email: string
  first_name: string
  frontend_user_setting: FrontendUserSettingType
  handle: string
  hashed_email: string | null
  html_email_preference: boolean
  invitation_accepted_at: number
  invitation_sent_at: null
  invited_by_id: number | null
  job_title: string | null
  last_name: string
  last_sign_in_at: string
  mobile_number: string | null
  name: string
  online: boolean | null
  permissions: {
    views: {
      mycutover: PermissionsRecordType
    }
  }
  pref_email: boolean
  pref_sms: boolean
  provider: string
  roles: string
  runbooks_involved_count: number
  secret_key: null | string
  sign_in_count: number
}

export function useGetValidateToken(options: UseQueryOptions<CurrentUser, ApiError>) {
  // TODO review types, we probably need a new type here as there are
  // slightly different properties being returned in this endpoint when
  // compared to the User type
  return useQuery<CurrentUser, ApiError>(
    'validate_token',
    async () => {
      const { data } = await apiClient.get<CurrentUser>({
        url: 'auth/validate_token',
        responseProperty: 'data'
      })

      return data
    },
    { ...options, staleTime: 0, cacheTime: 0 }
  )
}

export function useGetValidateTokenWithCookie(options: UseQueryOptions<CurrentUser, ApiError>) {
  // TODO review types, we probably need a new type here as there are
  // slightly different properties being returned in this endpoint when
  // compared to the User type
  return useQuery<CurrentUser, ApiError>(
    'validate_token_with_cookie',
    async () => {
      const { data } = await apiClient.get<CurrentUser>({
        url: 'auth/validate_token',
        responseProperty: 'data'
      })

      return data
    },
    {
      ...options,
      // @ts-ignore
      enabled: Boolean(Cookies.get('auth_headers')) && !Boolean(window.Cypress),
      staleTime: 0,
      cacheTime: 0
    }
  )
}
