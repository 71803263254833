import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Editor, Range } from 'slate'
import * as yup from 'yup'

import { Modal } from '../../../modal/modal'
import { TextInput } from '../../text-input/text-input'

type TextEditorLinkModalProps = {
  editor: Editor
  open: boolean
  onClose: () => void
  onInsertLink: (data: LinkPayload) => void
}

export type LinkPayload = {
  url: string | null
  text: string | null
}

export const TextEditorLinkModal = ({ editor, open, onClose, onInsertLink }: TextEditorLinkModalProps) => {
  const { selection } = editor
  const rangeSelected = selection && !Range.isCollapsed(selection)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<LinkPayload>({
    defaultValues: {
      url: 'https://',
      text: null
    },
    resolver: yupResolver(
      yup.object({
        url: yup.string().required(),
        text: yup
          .string()
          .when([], {
            is: () => !rangeSelected,
            then: schema => schema.required()
          })
          .nullable()
      })
    )
  })

  const onSubmit = (data: LinkPayload) => {
    onInsertLink(data)
    handleClose()
  }

  const handleClose = () => {
    reset()
    onClose()
  }

  const handleConfirm = () => {
    handleSubmit(onSubmit)()
  }

  return (
    <Modal title="Insert link" open={open} onClickConfirm={handleConfirm} onClose={handleClose} confirmText="Insert">
      <TextInput required hasError={!!errors.url} label="Link URL" {...register('url')} />
      {!rangeSelected && <TextInput required hasError={!!errors.text} label="Link text" {...register('text')} />}
    </Modal>
  )
}
