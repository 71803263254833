export type UserResponse = {
  id?: number
  name: string
  firstName: string
  lastName: string
  online: boolean | null
  handle: string
  color: string
  isAdmin: boolean | null
  notInAccount: boolean | null
  archived: boolean
  status: 'active' | 'invited' | 'not_allowed' | 'archived' | 'invalid' | 'external'
}

export type RunbookTeamResponse = {
  id: number
  name: string
  color: string
  linked: boolean
  usersCount: number
}

export type TeamResponse = {
  id: number
  name: string
  color: string
  highlight: boolean
  usersCount: number
  accountId: number
}

export type UserRecipient = UserResponse & {
  type: 'user'
}

export type TeamRecipient = {
  id: number
  name: string
  color: string
  usersCount: number
  type: 'team'
}

export type RecipientType = UserRecipient | TeamRecipient

export function isUserRecipient(arg: any): arg is UserRecipient {
  return arg.hasOwnProperty('handle')
}
