import { RunbooksDashboardSchedule } from 'main/components/workspace/pages/runbooks-dashboard/runbooks-dashboard-schedule'
import { useRightPanelTypeState } from 'main/components/layout/right-panel'

export const RunbooksDashboardSchedulePanel = () => {
  const [{ accountId, dashboardId, currentUserId }, { closeRightPanel }] =
    useRightPanelTypeState('runbook-dashboard-schedule')

  return (
    <>
      {!!accountId && !!dashboardId && !!currentUserId && (
        <RunbooksDashboardSchedule
          accountId={accountId}
          dashboardId={dashboardId}
          currentUserId={currentUserId}
          onClose={closeRightPanel}
        />
      )}
    </>
  )
}
