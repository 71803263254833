import { unescape } from 'lodash'

import { usePagedList } from '@cutover/react-ui'
import { apiClient } from 'main/services/api'

export type AccountUserTeam = {
  id: number
  name: string
  color: string
  type: 'user' | 'team'
  usersCount?: number
  highlight?: boolean
  online?: boolean | null
  linked?: boolean
}

export type AccountUsersTeamsQuery = {
  accountId: number
}

export function useAccountUsersTeams(query: AccountUsersTeamsQuery) {
  const { accountId } = query
  return usePagedList<AccountUserTeam>({
    load: async ({ filterText }) => {
      const { data } = await apiClient.get<AccountUserTeam[]>({
        url: `accounts/${accountId}/users_teams`,
        responseProperty: 'users_teams',
        params: {
          query: filterText
        },
        // FIXME: convertCase is deprecated
        convertCase: true
      })

      return {
        items: data.map(item => ({ ...item, name: unescape(item.name), linked: item.highlight })),
        cursor: undefined
      }
    },
    initialItems: []
  })
}
