export const routes = {
  toRunbook: ({
    accountSlug,
    runbookId,
    versionId,
    params = '',
    reactRunbook
  }: {
    accountSlug: string
    runbookId: number
    versionId?: number
    reactRunbook?: boolean
    params?: string
  }) =>
    `/app/${accountSlug}/runbooks/${runbookId}/${versionId ? versionId : 'current_version'}/tasks/${
      reactRunbook ? 'react-list' : 'list'
    }${params}`,

  toDashboard: ({
    accountSlug,
    runbookId,
    versionId,
    dashboardId,
    reactRunbook,
    params = ''
  }: {
    accountSlug: string
    runbookId: number
    versionId?: number
    dashboardId?: number
    reactRunbook?: boolean
    params?: string
  }) =>
    `/app/${accountSlug}/runbooks/${runbookId}/${versionId ? versionId : 'current_version'}/tasks/${
      reactRunbook ? 'react-dashboard' : 'dashboard'
    }/${dashboardId}${params}`
}
