import { useMutation, useQuery } from 'react-query'

import { Nullish } from '@cutover/utility-types'
import { apiClient } from 'main/services/api'
import { ApiError } from 'main/services/api/http-gateway-adapter'

export interface RunbookTypeCreate {
  name: string | null | undefined
  description: string | null | undefined
  visibility: 'global' | 'account'
  accountId: number | null | undefined
  dynamic: boolean
  incident: boolean
  enableRto: boolean
  restrictCreateToTemplates: boolean
  iconName: string | null | undefined
  iconColor: string | null | undefined
  approvalFlowId?: number | null | undefined
}

// TODO: add icon id and color to API, return 422 for duplicate
interface RunbookTypeCreateApi {
  name: string | null | undefined
  description: string | null | undefined
  visibility: 'global' | 'select_accounts'
  accountId: number | null | undefined
  dynamic: boolean
  incident: boolean
  enableRto: boolean
  restrictCreateToTemplates: boolean
  global?: boolean
  approvalFlowId?: number | null | undefined
}

export function useRunbookTypeCreate() {
  return useMutation<RunbookTypeCreate, ApiError, RunbookTypeCreate>(
    'runbook_type',
    async (runbookType: RunbookTypeCreate) => {
      const isGlobal = runbookType.visibility === 'global'

      const runbookTypeApi: RunbookTypeCreateApi = {
        ...runbookType,
        visibility: isGlobal ? 'global' : 'select_accounts',
        ...(isGlobal ? { global: true } : {})
      }

      // TODO: update post response type
      const { data } = await apiClient.post<RunbookTypeCreateApi, RunbookTypeCreate>({
        url: 'runbook_types',
        data: runbookTypeApi,
        requestProperty: 'runbook_type',
        // FIXME: convertCase is deprecated
        convertCase: true
      })

      // TODO: data type should be truthy on success
      return data as RunbookTypeCreate
    }
  )
}

// TODO: review other properties
export type RunbookTypeApi = {
  id: number
  name: string
  accountId: Nullish<number>
  accountName: string
  archived: boolean
  default: boolean
  description: string
  disabled: boolean
  dynamic: boolean
  incident: boolean
  global: boolean
  iconColor: string
  iconName: string
  // change_request_types": []
  // "runbook_templates_count": 0,
  // "permissions": {
  //   "update": [
  //     1
  //   ],
  //   "destroy": [
  //     1
  //   ],
  //   "global": [
  //     1
  //   ]
  // }
}

// TODO: review other JSON reponse
export type RunbookType = {
  id: number
  name: string
  description: string
  visibility: 'global' | 'account'
  accountId: Nullish<number>
  dynamic: boolean
  incident: boolean
  disabled: boolean
  iconName: string
  iconColor: string
  // accountName: string
  // archived: boolean
  // default: boolean
}

export function useRunbookType(id: number) {
  return useQuery<RunbookType, Error, RunbookType>(
    ['runbook-type', id],
    async () => {
      const { data } = await apiClient.get<RunbookTypeApi>({
        url: `runbook_types/${id}`,
        responseProperty: 'runbook_type',
        // FIXME: convertCase is deprecated
        convertCase: true
      })

      return {
        ...data,
        visibility: data.global ? ('global' as const) : ('account' as const),
        iconName: data.iconName
      }
    },
    {
      cacheTime: 0
    }
  )
}

export type RunbookTypeEdit = {
  id: number
  name: string
  description: string
  visibility: 'global' | 'account'
  accountId: Nullish<number>
  dynamic: boolean
  incident: boolean
  iconName: string
  iconColor: string
  // accountName: string
  // archived: boolean
  // default: boolean
}

// TODO: review return type of useMutation call
export function useRunbookTypeEdit(id: number) {
  return useMutation<RunbookTypeEdit, Error, RunbookTypeEdit>('runbook-type', async (runbookType: RunbookTypeEdit) => {
    const { data } = await apiClient.put<RunbookTypeEdit, RunbookTypeEdit>({
      url: `runbook_types/${id}`,
      data: runbookType,
      requestProperty: 'runbook_type',
      // FIXME: convertCase is deprecated
      convertCase: true
    })

    if (!data) {
      throw new Error('Unexpected empty data response')
    }

    return data
  })
}
