import { useQuery } from 'react-query'

import { apiClient } from 'main/services/api/api-client'
import { QueryKeys } from 'main/services/queries/query-keys'

export type ValidatedObject = { id: number; name: string; linked_runbook_id?: number; internal_id?: number }

export type ValidatedError = {
  type: 'string'
  label: string
  count: number
  objects: ValidatedObject[]
}
export type ValidatedErrors = {
  [key: string]: ValidatedError
}
export type ValidatedWarning = ValidatedError
export type ValidatedWarnings = {
  [key: string]: ValidatedWarning
}

export type ValidateRunbookErrorResponse = {
  errors: ValidatedErrors
  status: boolean
  warnings: ValidatedWarnings
}

export function useValidateRunbook({
  runbookId,
  runbookVersionId,
  onError,
  enabled = true
}: {
  runbookId?: string | number
  runbookVersionId?: string | number
  onError: (
    response: { errors: ValidateRunbookErrorResponse; status: 422 } | { errors: { errors: string[] }; status: number }
  ) => void
  enabled?: boolean
}) {
  return useQuery(
    [QueryKeys.Runbooks, String(runbookId), QueryKeys.RunbookVersions, String(runbookVersionId), 'validate'],
    async () => {
      const data = await apiClient.get<{}>({
        url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/validate`,
        errorResponseProperty: null
      })

      return data
    },
    { onError, enabled: !!runbookId && !!runbookVersionId && enabled }
  )
}
