import { useMutation } from 'react-query'

import { apiClient } from 'main/services/api/api-client'
import {
  RunbookCommentCreateResponse,
  RunbookCommentsGetResponse,
  RunbookCommentToggleFeaturedResponse
} from '../api/data-providers/runbook-types'

type CommentCreatePayload = {
  comment: {
    content: string
    task_id?: number
  }
}

export function useRunbookCommentCreate(
  runbookId: number,
  runbookVersionId: number | string = 'current_version',
  taskId?: number
) {
  return useMutation(async (comment: string) => {
    const { data } = await apiClient.post<CommentCreatePayload, RunbookCommentCreateResponse>({
      url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/comments`,
      data: {
        comment: {
          content: comment,
          task_id: taskId
        }
      }
    })

    return data
  })
}

export const getRunbookComments = async ({
  runbookId,
  runbookVersionId
}: {
  runbookId: number
  runbookVersionId: number
}) => {
  const { data } = await apiClient.get<RunbookCommentsGetResponse>({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/comments?include_all=true`
  })

  return data
}

export const toggleCommentFeatured = async ({
  runbookId,
  runbookVersionId,
  commentId
}: {
  runbookId: number
  runbookVersionId: number
  commentId: number
}) => {
  const { data } = await apiClient.patch<null, RunbookCommentToggleFeaturedResponse>({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/comments/${commentId}/toggle_featured`
  })

  return data
}
