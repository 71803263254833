import { LegacyTableBase as Table } from '@cutover/react-ui'
import { DashboardUserItem } from './dashboard-user-item'
import { MrdDashboardWidget } from '../../../widgets/account/mrd-dashboard-widget'
import { DashboardComponent, DashboardMediaType } from '../../types'

export type RunbookAdminWidgetProps = {
  media: DashboardMediaType
  data: DashboardComponent
}

/**
 * Displays the Runbook Admins on a Runbook Dashboard
 */
export const RunbookAdminWidget = ({ data }: RunbookAdminWidgetProps) => {
  return (
    <MrdDashboardWidget title="Runbook Admins">
      <RunbookAdminWidgetContent componentData={data}></RunbookAdminWidgetContent>
    </MrdDashboardWidget>
  )
}

export const RunbookAdminWidgetContent = ({ componentData }: { componentData: DashboardComponent }) => {
  const admins = componentData?.users.filter(user => user.is_admin === true)
  return (
    <>
      <Table width="100%" style={{ cursor: 'default' }}>
        <Table.Head>
          {admins?.map(user => (
            <Table.Row key={`user${user.id}`}>
              <DashboardUserItem name={user.name} color={user.color || ''} handle={user.handle} />
            </Table.Row>
          ))}
        </Table.Head>
      </Table>
    </>
  )
}
