import { ReactNode } from 'react'
import styled from 'styled-components'

import { Box, breakpoints, colors, px2rem } from '@cutover/react-ui'

export type MrdDashboardWidgetProps = {
  title: string
  height?: 300
  fullWidth?: boolean
  notes?: string | null | undefined
  errors?: string[] | null
  children: ReactNode
}

export const MrdDashboardWidget = ({ title, fullWidth, children, errors, notes }: MrdDashboardWidgetProps) => {
  return (
    <Widget fullWidth={fullWidth}>
      <Box margin={{ bottom: '16px' }}>
        <Header data-testid="dashboard-widget">{title}</Header>
        {notes && <Notes>{notes}</Notes>}
        {errors && <Errors>{errors}</Errors>}
      </Box>
      {children}
    </Widget>
  )
}

const Header = styled.h3`
  color: ${colors.textDefault};
  font-size: 18px;
  font-weight: 700;
  margin: 0;
`

const Notes = styled.div`
  margin-top: 16px;
  font-size: ${px2rem(13)};
  color: ${colors.textLight};
`

const Errors = styled.div`
  margin-top: 16px;
  color: ${colors.error};
  font-size: ${px2rem(13)};
`

const Widget = styled(Box)<{ fullWidth?: boolean }>`
  display: block;
  width: 100%;
  padding: 16px;
  /* using padding rather than margin to give donut-chart legends some breathing room */
  padding-bottom: 36px;
  overflow-y: scroll;

  @media print, ${breakpoints.gtLg} {
    float: left;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : '50%')};
    page-break-inside: ${({ fullWidth }) => (fullWidth ? 'auto' : 'avoid')};
  }
`
