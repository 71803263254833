import { AppsPayload } from './apps-types'
import { apiClient } from 'main/services/api'
import { RunbookIntegration, RunbookIntegrationActionItem } from 'main/services/queries/types'
import { RunbookIntegrationsResponse } from 'main/services/queries/use-runbook-integrations'
import { useSetAppsConnectionLost } from 'main/recoil/data-access'

export type AvailableIntegrationsResponse = {
  integration_links: RunbookIntegration[]
}

type IntegrationActionItemResponse = {
  integration_action_item: RunbookIntegrationActionItem
}

export const usePostAppEvents = () => {
  const setConnectionLost = useSetAppsConnectionLost()

  return async (payload: AppsPayload) => {
    try {
      const data = await apiClient.post({
        url: 'app_events',
        data: { ...payload, session_id: renderBrowserHashFromStorage() }
      })
      return data
    } catch {
      setConnectionLost(true)
    }
  }
}

export function renderBrowserHashFromStorage() {
  let browserHash = window.sessionStorage.getItem('browserHash')

  if (!browserHash) {
    browserHash = Math.random().toString(14).slice(2)
    window.sessionStorage.setItem('browserHash', browserHash)
  }

  return browserHash
}

export const getAvailableIntegrations = async (runbookId: string): Promise<RunbookIntegrationsResponse> => {
  const { data } = await apiClient.get<RunbookIntegrationsResponse>({
    url: `runbooks/${runbookId}/runbook_versions/current_version/integrations`,
    // FIXME: convertCase is deprecated
    convertCase: true
  })
  return data
}

export const getIntegrationActionItem = async (
  integrationActionItemId: string
): Promise<IntegrationActionItemResponse> => {
  const { data } = await apiClient.get<IntegrationActionItemResponse>({
    url: `integration_action_items/${integrationActionItemId}`
  })
  return data
}
