import styled from 'styled-components'

import {
  LegacyBox as Box,
  breakpoints,
  colors,
  DonutChart,
  DonutChartDatum,
  LegacyFlex as Flex,
  LegacyFlexItem as FlexItem,
  px2rem,
  LegacyTableBase as Table
} from '@cutover/react-ui'
import { getCustomFieldSummaryDataArray } from './get-cf-summary-data-array'
import { MrdDashboardWidget } from '../../../widgets/account/mrd-dashboard-widget'
import { FieldOptionSummaryData, GenericWidgetProps } from '../../types'
import { abbreviateNumber } from 'main/services/formatter'
import { useFilterTaskData } from 'main/components/dashboards/runbook-dashboard/use-task-filtering'

// used for the right side table in the widget
type TableData = {
  name: string
  count: number
  percentageText: string
}

export const CustomFieldSummaryWidget = ({ media, data }: GenericWidgetProps) => {
  const customFieldId =
    typeof data.settings === 'string' ? JSON.parse(data.settings).custom_field_id : data.settings.custom_field_id
  const customField = data.custom_fields.find(field => field.id === customFieldId)
  const title = data.display_name ?? `${customField?.name} field summary`

  const filteredData = useFilterTaskData(data)
  const filteredTasks = data.filtered_tasks || filteredData

  const summaryDataArray = getCustomFieldSummaryDataArray(filteredTasks, customField)
  const total = summaryDataArray.find(data => data.name === 'Total')?.count || data.tasks.length

  const donutData: DonutChartDatum[] = summaryDataArray
    .map((fieldOption: FieldOptionSummaryData) => ({
      id: fieldOption.name,
      label: fieldOption.name,
      name: fieldOption.name,
      value: fieldOption.count,
      color: fieldOption.color
    }))
    .filter(fo => fo.name !== 'Total')

  const isEmpty = donutData.every(item => item.value === 0)

  const tableData: TableData[] = summaryDataArray.map((fieldOption: FieldOptionSummaryData) => ({
    name: fieldOption.name,
    count: Number(fieldOption.count),
    percentageText: data.tasks.length
      ? `${fieldOption.name === 'Total' ? '100' : Math.round((fieldOption.count / total) * 100)}%`
      : '0%'
  }))

  const donutHeight = 175

  return (
    <MrdDashboardWidget fullWidth title={title}>
      <Flex flexDirection="row" flexWrap="wrap" justifyContent="space-between">
        <ContentContainer media={media}>
          <DonutContainer>
            {isEmpty ? (
              <EmptyChart data={donutData} height={donutHeight} />
            ) : (
              <DonutChart
                data={donutData}
                withKey
                centerValue={`${total}`}
                height={donutHeight}
                data-testid="custom-field-summary-donut"
              />
            )}
          </DonutContainer>
        </ContentContainer>
        <ContentContainer media={media}>
          <Table
            width="100%"
            css={`
              padding-left: ${px2rem(16)};
            `}
          >
            <Table.Head>
              <Table.Row>
                <Table.Header width="50%"></Table.Header>
                <Table.Header width="25%">Total</Table.Header>
                <Table.Header width="25%" highlight={colors.warningBg}>
                  Percent
                </Table.Header>
              </Table.Row>
            </Table.Head>

            <Table.Body>
              {tableData.map(d => (
                <Table.Row key={d.name}>
                  <Table.Cell width="50%">{d.name}</Table.Cell>
                  <Table.Cell width="25%">{abbreviateNumber(d.count)}</Table.Cell>
                  <Table.Cell width="25%" highlight={colors.warningBg} css="align-self: stretch;">
                    {d.percentageText}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </ContentContainer>
      </Flex>
    </MrdDashboardWidget>
  )
}

const EmptyChart = ({ data, height }: { data: DonutChartDatum[]; height: number }) => {
  const emptyData = data.map(d => ({
    ...d,
    color: colors.primaryGreyHoverBackgroundColor,
    value: 100 / data.length
  }))

  // this is dependent on the donut chart layer order
  return (
    <>
      <EmptyDonutContainer layer={1}>
        <DonutChart data={data} withKey height={height} />
      </EmptyDonutContainer>
      <EmptyDonutContainer layer={2}>
        <DonutChart data={emptyData} segmentGap={3} withKey centerValue="0%" height={height} />
      </EmptyDonutContainer>
    </>
  )
}

const ContentContainer = styled(FlexItem)<{ media: GenericWidgetProps['media'] }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: ${({ media }) => (media === 'screen' ? '0' : `${px2rem(10)}`)};
  width: ${({ media }) => (media === 'screen' ? '49%' : '100%')};
  overflow: hidden;

  @media ${breakpoints.sm} {
    flex-direction: column;
    width: 100%;
  }
`

const DonutContainer = styled(Box)<{ media: GenericWidgetProps['media'] }>`
  display: flex;
  position: relative;
  justify-content: center;
  margin-bottom: ${({ media }) => (media === 'screen' ? '0' : `${px2rem(10)}`)};
  width: ${({ media }) => (media === 'screen' ? '49%' : '100%')};
  min-height: ${px2rem(200)};
  align-items: center;

  @media ${breakpoints.sm} {
    width: 100%;
  }
`

const EmptyDonutContainer = styled(Box)<{ layer: number }>`
  position: absolute;
  & svg > g > ${props => (props.layer === 2 ? 'g:nth-child(2)' : 'g:first-child')} {
    visibility: hidden;
  }
`
