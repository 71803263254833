import { toCamelCase, toSnakeCase } from './case'

const isObject = (value?: unknown): value is Record<string, unknown> =>
  Object.prototype.toString.call(value) === '[object Object]'

export function processRequestData({
  data,
  convertCase,
  property
}: {
  data: any
  /** @deprecated */
  convertCase?: boolean
  property?: string
}) {
  if (!data) {
    return undefined
  }

  if (convertCase) {
    data = toSnakeCase(data)
  }

  if (property) {
    data = {
      [property]: data
    }
  }

  return data
}

export function processResponseData({
  data,
  convertCase,
  property
}: {
  data: any
  /** @deprecated */
  convertCase?: boolean
  property?: string
}) {
  if (!data) {
    return undefined
  }

  if (property) {
    if (!isObject(data)) {
      throw new Error('Unexpected property value specified for a non object response.')
    }

    data = data[property]
  }

  return convertCase ? toCamelCase(data) : data
}
