import { keyBy } from 'lodash'
import { atom, selector, selectorFamily } from 'recoil'

import { queryClient } from 'main/query-client'
import { apiClientCompat_UNSTABLE } from 'main/services/api'
import { QueryKeys } from 'main/services/queries/query-keys'
import { Favorite } from 'main/services/queries/types'

export const fetchFavoritesQuery = async () => {
  return await queryClient.fetchQuery({
    queryKey: [QueryKeys.Favorites],
    queryFn: async () => {
      const { data } = await apiClientCompat_UNSTABLE.get<{ runbooks: Favorite[] }>({
        url: 'favorites'
      })
      return data.runbooks
    }
  })
}

export const favoritesState = atom<Favorite[] | undefined>({
  key: 'favorites',
  default: selector({
    key: 'favorites:default',
    get: () => {
      return fetchFavoritesQuery()
    }
  })
})

export const favoritesRecord = selector({
  key: 'favorites:record',
  get: ({ get }) => keyBy(get(favoritesState), 'id')
})

export const favoriteStateById = selectorFamily<Favorite, number>({
  key: 'favorite:by-id',
  get:
    id =>
    ({ get }) => {
      return get(favoritesRecord)[id]
    }
})
