// @ts-nocheck
import { injectable, inject } from 'inversify'
import { observable } from 'mobx'
import { IServiceGateway } from 'Gateways/Service/IServiceGateway'
import { Types } from 'Gateways/Service/Types'

@injectable()
export class ConfigsRepository {
  @inject(Types.IServiceGateway)
  public serviceGateway: IServiceGateway

  @observable
  public version = undefined

  @observable
  public colors = undefined

  @observable
  public taskTypeComms = undefined

  @observable
  public views = undefined

  @observable
  public resourceTypes = undefined

  @observable
  public capabilityTypes = undefined

  @observable
  public dateWithinOptions = undefined

  @observable
  public runStages = undefined

  @observable
  public ragStatuses = undefined

  @observable
  public eventTypeStartIcons = undefined

  @observable
  public eventTypeEndIcons = undefined

  @observable
  public eventStatuses = undefined

  @observable
  public completionTypes = undefined

  @observable
  public startTypes = undefined

  @observable
  public templateStatuses = undefined

  @observable
  public componentData = undefined

  @observable
  public startRequirements = undefined

  @observable
  public endRequirements = undefined

  @observable
  public taskTypeIcons = undefined

  @observable
  public taskLevels = undefined

  @observable
  public fixedStartTypes = undefined

  @observable
  public taskTypeSubIcons = undefined

  @observable
  public customFieldTypes = undefined

  @observable
  public customFieldApplications = undefined

  @observable
  public dateTypes = undefined

  @observable
  public countries = undefined

  @observable
  public taskTypes = undefined

  @observable
  public taskStageTypes = undefined

  @observable
  public oauthActive = undefined

  @observable
  public oauthAllowSetLoginType = undefined

  @observable
  public oauthAllowEmailLogin = undefined

  @observable
  public oauthName = undefined

  @observable
  public oauthUniqueId = undefined

  @observable
  public owner = undefined

  @observable
  public cutoverApiFullPath = undefined

  @observable
  public datadogRUMClientId = undefined

  @observable
  public datadogRUMDatacenter = undefined

  @observable
  public taskTypeCall = undefined

  @observable
  public taskTypeEmail = undefined

  @observable
  public taskTypeSms = undefined

  @observable
  public resourceContexts = undefined

  @observable
  public heapAppId = undefined

  @observable
  public heapActive = undefined

  @observable
  public intercomAppId = undefined

  @observable
  public delightedAppId = undefined

  @observable
  public taskTypeVideo = undefined

  @observable
  public runTypes = undefined

  @observable
  public deviseMaxSessionTimeout = undefined

  @observable
  public customSessionExpiryRedirect = undefined

  @observable
  public loginLogo = undefined

  @observable
  public topRightLogo = undefined

  @observable
  public inviteMessage = undefined

  @observable
  public integrations = undefined

  @observable
  public integrationHooks = undefined

  @observable
  public mailerDNS = undefined

  @observable
  public loginWarningMessage = undefined

  @observable
  public userAppTokenExpiry = undefined

  @observable
  public enabledFeatures: string[] = []

  @observable
  public runbookTypeIcons: string[] = []

  @observable
  public runbookTypeColors: string[] = []

  @observable
  public maxRunbookTeams: number = undefined

  @observable
  public maxRunbookUsers: number = undefined

  private isLoaded = false

  private raw: any = {}

  public load = async (cache = true) => {
    if (!this.isLoaded) {
      const { body } = await this.serviceGateway.get('configs')
      this.raw = body
      const {
        version,
        colors,
        task_type_comms,
        views,
        resource_types,
        capability_types,
        date_within_options,
        run_stages,
        rag_statuses,
        event_type_start_icons,
        event_type_end_icons,
        event_statuses,
        completion_types,
        start_types,
        template_statuses,
        component_data,
        start_requirements,
        end_requirements,
        task_type_icons,
        task_levels,
        fixed_start_types,
        task_type_sub_icons,
        custom_field_types,
        custom_field_applications,
        date_types,
        countries,
        task_types,
        task_stage_types,
        oauth_active,
        oauth_allow_set_login_type,
        oauth_allow_email_login,
        oauth_name,
        oauth_unique_id,
        owner,
        cutover_api_full_path,
        datadog_rum_client_id,
        datadog_rum_datacenter,
        task_type_call,
        task_type_email,
        task_type_sms,
        heap_app_id,
        intercom_app_id,
        delighted_app_id,
        task_type_video,
        run_types,
        devise_max_session_timeout,
        custom_session_expiry_redirect,
        login_logo,
        top_right_logo,
        invite_message,
        integrations,
        integration_hooks,
        mailer_dns,
        login_warning_message,
        user_app_token_expiry,
        enabled_features,
        runbook_type_icons,
        runbook_type_colors,
        max_runbook_teams,
        max_runbook_users,
        api_max_requests_per_minute
      } = body

      this.version = version
      this.colors = colors
      this.taskTypeComms = task_type_comms
      this.views = views
      this.resourceTypes = resource_types
      this.capabilityTypes = capability_types
      this.dateWithinOptions = date_within_options
      this.runStages = run_stages
      this.ragStatuses = rag_statuses
      this.eventTypeStartIcons = event_type_start_icons
      this.eventTypeEndIcons = event_type_end_icons
      this.eventStatuses = event_statuses
      this.completionTypes = completion_types
      this.startTypes = start_types
      this.templateStatuses = template_statuses
      this.componentData = component_data
      this.startRequirements = start_requirements
      this.endRequirements = end_requirements
      this.taskTypeIcons = task_type_icons
      this.taskLevels = task_levels
      this.fixedStartTypes = fixed_start_types
      this.taskTypeSubIcons = task_type_sub_icons
      this.customFieldTypes = custom_field_types
      this.customFieldApplications = custom_field_applications
      this.dateTypes = date_types
      this.countries = countries
      this.taskTypes = task_types
      this.taskStageTypes = task_stage_types
      this.oauthAllowSetLoginType = oauth_allow_set_login_type
      this.oauthAllowEmailLogin = oauth_allow_email_login
      this.oauthActive = oauth_active
      this.oauthName = oauth_name
      this.oauthUniqueId = oauth_unique_id
      this.owner = owner
      this.cutoverApiFullPath = cutover_api_full_path
      this.datadogRUMClientId = datadog_rum_client_id
      this.datadogRUMDatacenter = datadog_rum_datacenter
      this.taskTypeCall = task_type_call
      this.taskTypeEmail = task_type_email
      this.taskTypeSms = task_type_sms
      this.heapActive = false
      this.heapAppId = heap_app_id
      this.intercomAppId = intercom_app_id
      this.delightedAppId = delighted_app_id
      this.taskTypeVideo = task_type_video
      this.runTypes = run_types
      this.deviseMaxSessionTimeout = devise_max_session_timeout
      this.customSessionExpiryRedirect = custom_session_expiry_redirect
      this.loginLogo = top_right_logo
      this.inviteMessage = invite_message
      this.integrations = integrations
      this.integrationHooks = integration_hooks
      this.mailerDNS = mailer_dns
      this.loginWarningMessage = login_warning_message
      this.userAppTokenExpiry = user_app_token_expiry
      this.enabledFeatures = enabled_features
      this.runbookTypeIcons = runbook_type_icons
      this.runbookTypeColors = runbook_type_colors
      this.maxRunbookTeams = max_runbook_teams
      this.maxRunbookUsers = max_runbook_users
      this.apiMaxRequestsPerMinute = api_max_requests_per_minute

      if (cache) {
        this.isLoaded = true
      }
    }
  }

  public asRaw = () => {
    return this.raw
  }

  public setResourceContexts = () => {
    if (this.isLoaded && this.resourceContexts === undefined) {
      const resourceContexts = {}
      Object.keys(this.resourceTypes).forEach(resourceTypeKey => {
        const resourceContext = this.resourceTypes[resourceTypeKey]

        if (resourceContext.hasOwnProperty('context')) {
          resourceContexts[resourceTypeKey] = JSON.parse(JSON.stringify(resourceContext))
        }
      })
      this.resourceContexts = resourceContexts
    }
  }

  public getConfigAsLabelValue = (
    property: string,
    valueAsName: boolean = false
  ): { label: string; value: string }[] => {
    return Object.keys(this[property]).map(propertyKey => {
      const propertyValue = this[property][propertyKey]

      return {
        label: propertyValue.name,
        value: valueAsName ? propertyValue.name : propertyKey
      }
    })
  }
}
