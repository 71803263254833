import { Dispatch, SetStateAction, useEffect } from 'react'
import { saveAs } from 'file-saver'
// @ts-ignore
import { Controller, useForm } from 'react-hook-form'

import { Form, Modal, RadioboxGroup, useNotify } from '@cutover/react-ui'
import { serverQueryObjectToServerQueryString } from 'main/components/shared/filter/filter-params'
import { useFilters } from 'main/components/shared/filter/filter-provider'
import { apiClient } from 'main/services/api'
import { ExportRunbooksDownloadResponse } from 'main/services/api/data-providers/user/user-channel-response-types'
import { useLanguage, useUserWebsocket } from 'main/services/hooks'
import { RunbookTemplateType } from 'main/services/queries/types'

type ExportRunbooksModalProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  onClickClose?: () => void
  accountSlug?: string
  templateType: RunbookTemplateType
}

type ExportFormatValues = { format: 'csv' | 'xlsx' }

export const ExportRunbooksModal = ({
  open,
  setOpen,
  onClickClose,
  accountSlug,
  templateType
}: ExportRunbooksModalProps) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'exportRunbooksModal' })
  const { listen } = useUserWebsocket()
  const notify = useNotify()
  const filters = useFilters()
  const filterParams = serverQueryObjectToServerQueryString({ queryObject: filters.filters }).slice(1)

  const { handleSubmit, control, reset, formState } = useForm<ExportFormatValues>({
    mode: 'onSubmit',
    defaultValues: { format: 'csv' }
  })
  const { isSubmitting } = formState
  useEffect(() => {
    if (!open) {
      reset()
    }
  }, [open])

  useEffect(() => {
    listen(data => handleSaveFileResponse(data as ExportRunbooksDownloadResponse))
  }, [])

  const handleSaveFileResponse = (data: ExportRunbooksDownloadResponse) => {
    if (data?.meta?.headers?.request_method === 'download_file') {
      try {
        fetch(data.uri)
          .then(function (res) {
            return res.blob()
          })
          .then(function (blob) {
            saveAs(blob, data.filename)
            notify.success(t('messages.success'))
          })
      } catch (error) {
        notify.error(t('messages.error'))
      }
    }
  }

  const exportRunbooks = async (data: ExportFormatValues) => {
    const { format } = data

    await apiClient
      .get({
        url: `runbooks.${format}?accountId=${accountSlug}&${filterParams}&template_type=${templateType}&filter_reload=0`,
        config: {
          responseType: 'arraybuffer'
        }
      })
      .then(() => {
        notify.success(t('messages.exporting'), { title: t('messages.titles.exporting', { context: templateType }) })
      })
    handleCloseModal()
  }

  const handleCloseModal = () => {
    setOpen(false)
    onClickClose && onClickClose()
  }

  return (
    <Modal
      title={t('title', { context: templateType })}
      open={open}
      confirmText={t('buttons.confirmText')}
      confirmIcon="download"
      onClickConfirm={handleSubmit(exportRunbooks)}
      onClose={handleCloseModal}
      loading={isSubmitting}
    >
      <Form>
        <Controller
          name="format"
          control={control}
          render={({ field: { onChange, value } }) => (
            <RadioboxGroup
              name="format"
              label={t('labels.chooseFormat')}
              direction="row"
              value={value}
              onChange={onChange}
              options={[
                { label: t('labels.csv'), value: 'csv', autoFocus: true },
                { label: t('labels.excel'), value: 'xlsx' }
              ]}
            />
          )}
        />
      </Form>
    </Modal>
  )
}
