import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { Box, IconButton, media, TextArea } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { useRunbookCommentCreate } from 'main/services/queries/use-runbook-comments'

export type ActivityFeedInputProps = {
  runbookId: number
}

const ActivityFeedInputWrapper = styled(Box).attrs(() => ({
  flex: false
}))`
  width: calc(100% - 7px);
  background-color: white;
  align-items: center;
  z-index: 4;
  max-height: 50%;
  ${media.sm(css`
    padding: 0 10px;
  `)}
`

const SendCommentButtonWrapper = styled(Box).attrs(() => ({
  pad: { top: 'small' }
}))`
  justify-content: flex-start;
  min-width: 48px;
`

export const ActivityFeedInput = ({ runbookId }: ActivityFeedInputProps) => {
  const { t } = useLanguage('activities')
  const inputRef = useRef<HTMLTextAreaElement | null>(null)
  const [comment, setComment] = useState('')
  const [touched, setTouched] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const commentMutation = useRunbookCommentCreate(runbookId)

  const onChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value)
  }

  useEffect(() => {
    if (inputRef.current && touched) {
      inputRef.current.focus()
    }
  }, [touched, comment])

  const onKeyDownHandler = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && comment.trim() && !e.shiftKey) {
      sendComment()
    }
  }

  const sendComment = async () => {
    setDisabled(true)
    setTouched(true)
    await commentMutation.mutateAsync(comment.trim())
    setComment('')
    setDisabled(false)
  }

  return (
    <ActivityFeedInputWrapper>
      <Box direction="row" background="bg" width="100%" gap="small">
        <TextArea
          ref={inputRef}
          plain={true}
          disabled={disabled}
          data-testid="activityInput"
          placeholder={t('activities:input:comment:placeholder')}
          aria-label={t('activities:input:comment:placeholder')}
          aria-describedby="instructions"
          value={comment}
          alwaysShowPlaceholder={true}
          onChange={onChangeHandler}
          onKeyDown={onKeyDownHandler}
          style={{
            outline: 'none',
            backgroundColor: '#f6f6f6',
            color: '#16161d',
            borderRadius: '8px',
            paddingLeft: '10px',
            boxSizing: 'border-box'
          }}
        />
        <SendCommentButtonWrapper>
          <IconButton
            primary
            disabled={!comment.trim()}
            data-testid="sendCommentButton"
            size="medium"
            icon="send"
            label={`${t('activities:feed:sendComment')}`}
            onClick={sendComment}
          />
        </SendCommentButtonWrapper>
      </Box>
    </ActivityFeedInputWrapper>
  )
}
