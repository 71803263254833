import { useCallback, useEffect, useMemo, useState } from 'react'
import { add, getUnixTime, set } from 'date-fns'

import { Box } from '../../layout'
import { Text } from '../../typography'
import { createLabelWithTimezone, getTimezoneOffsetString } from '../date-time-picker/date-utils'
import { FormField } from '../internal/form-field'
import { TimePicker } from '../time-picker'
import { NumberField } from '../time-picker/number-field'

type DayTimePickerProps = {
  value: Date // this would be the value to be changed (e.g. runbook.start_planned or task.fixed_start)
  dayZeroStartValue: Date // this would be the start value to compare against the value (e.g. runbook.current_version.start_planned)
  onChange: (value: Date) => void // this would be the callback to update the value (dayZeroStartValue does not change)
  disableDayPickerOnly?: boolean // disble day picker option for runbook edit panel (always 1)
  hasError?: boolean
  timezone?: string | null
  disabled?: boolean
  required?: boolean
  readOnly?: boolean
}

const SECONDS_IN_DAY = 60 * 60 * 24

export const DayTimePicker = ({
  dayZeroStartValue,
  value,
  onChange,
  disableDayPickerOnly,
  hasError,
  disabled,
  readOnly,
  timezone,
  required
}: DayTimePickerProps) => {
  // initial day (number) value based on dayZeroStartValue and value
  // (comparing value - e.g.runbook.start_planned or task.fixed_start)
  const day = Math.floor((getUnixTime(value) - getUnixTime(dayZeroStartValue)) / SECONDS_IN_DAY) + 1

  const [dayDisplay, setDayDisplay] = useState<number>(day)
  const [time, setTime] = useState<Date>(value)

  const timezoneOffsetString = useMemo(() => (timezone ? getTimezoneOffsetString({ timezone }) : ''), [timezone])

  const handleTimeChange = useCallback(
    (date: Date | null) => {
      // just pull the hours and minutes from the date
      const hours = (date ?? new Date()).getHours()
      const minutes = (date ?? new Date()).getMinutes()
      const newDate = set(time, { hours, minutes })
      setTime(newDate)
      onChange?.(newDate)
    },
    [time, onChange]
  )

  // on day change, we need to update the time value (adding a day to the time)
  const handleDayChange = useCallback(
    (num: number) => {
      if (num < 1) {
        return
      }
      const difference = num - dayDisplay
      const newDate = add(time, { days: difference })
      setTime(newDate)
      setDayDisplay(num)
      onChange?.(newDate)
    },
    [dayDisplay, time, onChange]
  )

  const defaultLabel = 'Day and time'

  useEffect(() => {
    setTime(value)
  }, [value])

  return (
    <FormField
      hasError={hasError}
      // @ts-ignore form-field label type is just a string
      label={timezone ? createLabelWithTimezone({ label: defaultLabel, timezoneOffsetString, required }) : defaultLabel}
      clickable={false}
      disabled={disabled}
      startIcon="stopwatch"
    >
      <Box direction="row" justify="between" fill pad={{ top: '4px', bottom: '4px' }}>
        <Box direction="row" align="center">
          <Text
            color={disabled || disableDayPickerOnly ? 'text-disabled' : 'text-light'}
            margin={{ left: '10px', right: '10px' }}
          >
            Day
          </Text>
          <NumberField
            value={dayDisplay}
            onChange={handleDayChange}
            label="Day"
            isDisabled={disabled || disableDayPickerOnly}
            isReadOnly={readOnly}
          />
        </Box>
        <Box direction="row" align="center">
          <Text color={disabled ? 'text-disabled' : 'text-light'} margin={{ right: '10px' }}>
            Time
          </Text>
          {time && (
            <TimePicker
              value={time}
              onChange={handleTimeChange}
              disabled={disabled}
              readOnly={readOnly}
              timezone={timezone}
            />
          )}
        </Box>
      </Box>
    </FormField>
  )
}
