import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Modal, RadioboxGroup } from '@cutover/react-ui'
import { saveFile } from 'Helpers/SaveFile'
import { apiClient } from 'main/services/api'
import { useLanguage } from 'main/services/hooks'

type ExportUsersModalProps = {
  onClose: () => void
  isOpen: boolean
  runbookId: number
  runbookVersionId: number
  runbookName: string
}

type ExportUsersFormValues = { exportType: string }

export const ExportUsersModal = ({
  isOpen,
  onClose,
  runbookId,
  runbookVersionId,
  runbookName
}: ExportUsersModalProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useLanguage('common')

  const { handleSubmit, control } = useForm<ExportUsersFormValues>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: { exportType: 'csv' }
  })

  const exportUsers = async (data: ExportUsersFormValues) => {
    const { exportType } = data
    setIsLoading(true)

    const response = await apiClient.get({
      url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/all_users_${exportType}?order=first_name&sort_direction=asc`,
      config: {
        responseType: 'arraybuffer'
      }
    })

    // TODO: handle response errors

    saveFile(
      response.data,
      exportType === 'csv' ? 'text/csv' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      `${runbookName}-users.${exportType}`
    )

    setIsLoading(false)
    onClose()
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={t('exportModal.title')}
      confirmText={isLoading ? t('exportUsersButton.loading') : t('exportUsersButton.static')}
      onClickConfirm={handleSubmit(exportUsers)}
    >
      <form>
        <Controller
          name="exportType"
          control={control}
          defaultValue="csv"
          render={({ field: { name, value, onChange, onBlur, ref } }) => (
            <RadioboxGroup
              name={name}
              value={value}
              onChange={onChange}
              direction="row"
              label={t('exportModal.radioButtonLabel')}
              onBlur={onBlur}
              ref={ref}
              options={[
                { value: 'csv', label: t('exportTypes.CSV') },
                { value: 'xlsx', label: t('exportTypes.Excel') }
              ]}
            />
          )}
        />
      </form>
    </Modal>
  )
}
