import { useMutation } from 'react-query'

import { apiClient } from 'main/services/api'
import { ApiError } from 'main/services/api/http-gateway-adapter'
import { UserStatus } from 'main/services/queries/types'

export type RunbookTeamReplaceProps = {
  runbookId: number
  runbookVersionId: number
  runbookTeamId: number
}

type RunbookTeamReplaceRequestType = {
  team: {
    name: string
    id: number
  }
}

export type RunbookTeamReplace = {
  runbookTeam: RunbookTeam
}

type RunbookTeam = {
  id: number
  teamId: number
  name: string
  roleTypes: RunbookTeamRoleTypeApi[]
  users: RunbookTeamUser[]
  usersCount: number
  linked?: boolean
  canUpdate: boolean
  canDelete: boolean
  roleTypeId: number
  avatar?: string
  color?: string
}

export type RunbookTeamUser = {
  id: number
  name: string
  color: string
  status: UserStatus
  online: boolean | null
}

export type RunbookTeamRoleTypeApi = {
  id: number
  name: string
  description?: string
  users: RunbookTeamUserApi[]
}

export type RunbookTeamRoleTypeUserApi = {
  id: number
  name: string
  handle: string
  color: string
  archived: boolean
}

export type RunbookTeamUserApi = {
  id: number
  name: string
  handle: string
  color: string
  archived?: boolean
  notInAccount?: boolean
  status: string
  online: boolean | null
}

export const useRunbookTeamReplace = ({ runbookId, runbookVersionId, runbookTeamId }: RunbookTeamReplaceProps) => {
  return useMutation<RunbookTeamReplace, ApiError, RunbookTeamReplaceRequestType>(
    'runbook-team-replace',
    async (payload: RunbookTeamReplaceRequestType) => {
      const { data } = await apiClient.patch<RunbookTeamReplaceRequestType, RunbookTeamReplace>({
        url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/runbook_teams/${runbookTeamId}/replace`,
        data: payload,
        // FIXME: convertCase is deprecated
        convertCase: true
      })

      return data as RunbookTeamReplace
    }
  )
}
