import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

import {
  colors,
  CompletionMetricTile,
  elapsedDays,
  LegacyFlex as Flex,
  LegacyFlexItem as FlexItem,
  duration as formatDuration,
  LegacyText as Text
} from '@cutover/react-ui'
import { computeTaskCompletionSummaryData, useTaskCompletionSummaryData } from './use-task-completion-summary-data'
import { filterTaskData, useFilterTaskData } from '../../../runbook-dashboard/use-task-filtering'
import { MrdDashboardWidget } from '../../../widgets/account/mrd-dashboard-widget'
import { DashboardComponent, DashboardMediaType } from '../../types'
import { useLanguage } from 'main/services/hooks'

export type TaskCompletionSummaryWidgetProps = {
  media: DashboardMediaType
  data: DashboardComponent
}

/**
 * Displays the Task Count Summary on a Runbook Dashboard
 */
export const TaskCompletionSummaryWidget = (props: TaskCompletionSummaryWidgetProps) => {
  const { media } = props

  switch (media) {
    case 'screen':
      return <BrowserWidget {...props} />
    case 'email':
      return <EmailWidget {...props} />
    case 'print':
      return <EmailWidget {...props} />
  }
}

const EmailWidget = ({ data }: { data: DashboardComponent }) => {
  const { timezone } = data
  const filteredTasks = filterTaskData(data)
  const values = computeTaskCompletionSummaryData(filteredTasks)

  return (
    <MrdDashboardWidget title={data.name} fullWidth>
      {filteredTasks.length === 0 ? (
        <Text color={colors.primaryGrey}>No Tasks Found</Text>
      ) : (
        <WidgetContent timezone={timezone} {...values} />
      )}
    </MrdDashboardWidget>
  )
}

const BrowserWidget = ({ data }: { data: DashboardComponent }) => {
  const { t } = useLanguage('dashboard', { keyPrefix: 'taskCompletionSummary' })
  const { timezone } = data
  const filteredTasks = useFilterTaskData(data)
  const values = useTaskCompletionSummaryData(filteredTasks)

  return (
    <MrdDashboardWidget title={data.name || t('title')} fullWidth>
      {filteredTasks.length === 0 ? (
        <Text color={colors.primaryGrey}>No Tasks Found</Text>
      ) : (
        <WidgetContent timezone={timezone} {...values} />
      )}
    </MrdDashboardWidget>
  )
}

type CompletionMetricData = {
  planned?: number
  display?: number
  diff?: {
    value: number
    early: boolean
  }
}

type WidgetContentProps = {
  start: CompletionMetricData
  end: CompletionMetricData
  duration: CompletionMetricData
  timezone: string
}

const WidgetContent = ({ start, end, duration, timezone }: WidgetContentProps) => {
  return (
    <Flex justifyContent="space-between" flexWrap="wrap">
      <FlexItem flex="0 1 32.5%">
        <CompletionMetricTile
          label="start"
          timeString={start.display === undefined ? undefined : formatTime(start.display, timezone)}
          dateString={start.display === undefined ? undefined : formatDate(start.display, timezone)}
          diff={
            start.diff === undefined
              ? undefined
              : {
                  value: formatDuration(start.diff.value),
                  type: start.diff.early ? 'early' : 'late'
                }
          }
        />
      </FlexItem>
      <FlexItem flex="0 1 32.5%">
        <CompletionMetricTile
          label="finish"
          timeString={end.display === undefined ? undefined : formatTime(end.display, timezone)}
          dateString={end.display === undefined ? undefined : formatDate(end.display, timezone)}
          diff={
            end.diff === undefined
              ? undefined
              : {
                  value: formatDuration(end.diff.value),
                  type: end.diff.early ? 'early' : 'late'
                }
          }
        />
      </FlexItem>
      <FlexItem flex="0 1 32.5%">
        <CompletionMetricTile
          label="duration"
          timeString={duration.display === undefined ? undefined : elapsedDays(duration.display)}
        />
      </FlexItem>
    </Flex>
  )
}

const formatTime = (ts: number, timezone: string): string => {
  const utcDate = new Date(ts * 1000)
  const dateInZone = utcToZonedTime(utcDate, timezone)
  return format(dateInZone, 'HH:mm')
}

const formatDate = (ts: number, timezone: string): string => {
  const utcDate = new Date(ts * 1000)
  const dateInZone = utcToZonedTime(utcDate, timezone)
  return format(dateInZone, 'd LLL yyyy')
}
