import { useCallback } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'
import { RUNBOOK_MIGRATION_ROUTES } from 'main/../single-spa/route-matchers'

import { routes } from './routes'
import { useWorkspaceData } from 'main/services/api/data-providers/workspace'
import { useAccountRunbookTypes } from 'main/services/api/data-providers/account/account-data'
import { ConfigModel } from 'main/data-access'

export const useRouting = () => {
  const isReactRunbookEnabled = ConfigModel.useIsFeatureEnabled('react_runbook')
  const { runbookLookup } = useWorkspaceData()
  const { runbookTypeLookup } = useAccountRunbookTypes()

  const toRunbook = useCallback(
    ({ runbookId, ...params }: Parameters<typeof routes.toRunbook>[0]) => {
      const runbookTypeId = runbookLookup?.[runbookId as number]?.runbook_type_id
      const runbookType = runbookTypeId ? runbookTypeLookup?.[runbookTypeId] : undefined
      const shouldOpenReactRunbook = isReactRunbookEnabled && !runbookType?.incident

      return routes.toRunbook({
        runbookId,
        ...params,
        reactRunbook: shouldOpenReactRunbook
      })
    },
    [isReactRunbookEnabled, runbookLookup, runbookTypeLookup]
  )

  return { toRunbook }
}

export const useIsOnReactRunbook = () => {
  const isReactRunbookEnabled = ConfigModel.useIsFeatureEnabled('react_runbook')
  const displayMatch = useMatch('/app/:accountId/runbooks/:runbookId/:runbookVersionId/tasks/:displayType/*')
  const currentPage = displayMatch?.params.displayType

  return !!(isReactRunbookEnabled && currentPage && RUNBOOK_MIGRATION_ROUTES.includes(currentPage))
}

export const useNavigateUpdateRunbookVersion = () => {
  const navigate = useNavigate()
  const match = useMatch('/app/:accountSlug/runbooks/:runbookId/:runbookVersionId/tasks/:displayType')

  return useCallback((versionId: number) => {
    const runbookId = match?.params.runbookId as string
    const accountSlug = match?.params.accountSlug as string

    // WARNING: only possible in react runbook at this time
    navigate(routes.toRunbook({ accountSlug, runbookId: parseInt(runbookId), versionId, reactRunbook: true }))
  }, [])
}
