import { forwardRef, ReactNode, Ref, useEffect, useMemo, useRef, useState } from 'react'
import {
  TextInput as GrommetTextInput,
  TextInputProps as GrommetTextInputProps,
  MaskedInput,
  MaskedInputProps
} from 'grommet'
import { useMergeRefs } from 'use-callback-ref'

import { IconName } from '../../../icon'
import { FormField, FormFieldLabelBoxProps } from '../form-field'

// This component is not exported into the app, it is to be used as the basis for the
// basic TextInput and other components such as Selects and Pickers where there a text
// field and animated label.

export type TextInputBaseProps = Omit<GrommetTextInputProps, 'plain'> & {
  startIcon?: IconName
  endComponent?: ReactNode
  hasError?: boolean
  inlineError?: string
  clickable?: boolean
  truncate?: boolean
  label?: string
  tooltipText?: string
  selectedItems?: ReactNode
  shrinkLabel?: boolean
  expandLabel?: boolean
  labelProps?: FormFieldLabelBoxProps
  labelSuffix?: string
  onClickLabelSuffixButton?: () => void
  labelSuffixButtonText?: string
  containerRef?: Ref<HTMLDivElement>
  plain?: boolean
  mask?: MaskedInputProps['mask']
}

export const TextInputBase = forwardRef<HTMLInputElement, TextInputBaseProps>(
  (
    {
      readOnly,
      onFocus,
      onBlur,
      onChange,
      onClick,
      startIcon,
      endComponent,
      hasError,
      inlineError,
      clickable,
      truncate,
      disabled,
      required,
      label,
      tooltipText,
      placeholder,
      selectedItems,
      labelSuffix,
      onClickLabelSuffixButton,
      labelSuffixButtonText,
      shrinkLabel,
      expandLabel,
      labelProps = {},
      type = 'text',
      plain,
      ...props
    },
    ref
  ) => {
    const hasInitialValue = useMemo(() => !!props.value || !!props.defaultValue, [])

    const localRef = useRef<HTMLInputElement>(null)
    const mergedRef = useMergeRefs([localRef, ref])

    const [isLabelShrunk, setLabelShrunk] = useState(
      props.autoFocus || (!expandLabel && (shrinkLabel || hasInitialValue))
    )
    const [isFocused, setFocused] = useState(false)
    const [animationInitialized, setAnimationInitialized] = useState(false)

    const hasValueOrFocus = () => Boolean(mergedRef?.current?.value) || (isFocused && !readOnly)

    useEffect(() => {
      setLabelShrunk(Boolean(mergedRef?.current?.value))
    }, [])

    useEffect(() => {
      setLabelShrunk(hasValueOrFocus())
    }, [hasValueOrFocus])

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      if (!animationInitialized) setAnimationInitialized(true)
      onFocus?.(event)
      setFocused(true)
    }

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      onBlur?.(event)
      setFocused(false)
      setLabelShrunk(hasValueOrFocus())
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event)
      setLabelShrunk(event.target.value.length > 0)
    }

    const isLabelSmall = !expandLabel && (shrinkLabel || isLabelShrunk)
    const showPlaceholder = !label || isLabelSmall
    const Input = props.mask ? MaskedInput : GrommetTextInput

    return (
      <FormField
        label={label}
        labelSize={isLabelSmall ? 'small' : 'medium'}
        startIcon={startIcon}
        truncate={truncate}
        clickable={clickable}
        onClick={onClick}
        disabled={disabled}
        readOnly={readOnly}
        required={required}
        hasError={hasError}
        inlineError={inlineError}
        endComponent={endComponent}
        selectedItems={selectedItems}
        labelProps={labelProps}
        labelSuffix={isLabelSmall ? labelSuffix : undefined}
        onClickLabelSuffixButton={onClickLabelSuffixButton}
        labelSuffixButtonText={labelSuffixButtonText}
        helpText={tooltipText}
        plain={plain}
      >
        <Input
          {...props}
          ref={mergedRef}
          type={type}
          disabled={disabled}
          readOnly={readOnly}
          required={required}
          // @ts-ignore
          placeholder={showPlaceholder ? placeholder : undefined}
          plain
          onChange={handleChange}
          // @ts-ignore
          onBlur={handleBlur}
          onFocus={handleFocus}
          a11yTitle={props.a11yTitle ?? label}
          aria-invalid={!!hasError}
        />
      </FormField>
    )
  }
)
