import { apiClient } from 'main/services/api'

type CsvImportArgs = {
  runbookId: number | string
  runbookVersionId: number | string
  formData: any
}

export const importTasksCsv = async ({ formData, runbookId, runbookVersionId }: CsvImportArgs) => {
  const { data } = await apiClient.post({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/csvimport`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return data
}

export type ApiResponse = {
  data: Blob
}

export const downloadRunbookCSVTemplate = async (): Promise<ApiResponse> => {
  const response = await apiClient.get({
    url: 'runbooks/csv_import_template'
  })
  return { data: response.data } as ApiResponse
}
