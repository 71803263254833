import { ReactNode } from 'react'
import Markdown from 'markdown-to-jsx'

import { Button } from '../button'
import { Icon, IconName } from '../icon'
import { Box } from '../layout/box'
import { themeColor } from '../theme'
import { Text } from '../typography'

type MessageProps = {
  message?: string | string[] | null
  children?: ReactNode
  type?: MessageType
  role?: string
  onClose?: () => void
  'data-testid'?: string
  margin?: boolean
}

export type MessageType = 'error' | 'success' | 'warning' | 'info' | 'idea'

export const MESSAGE_TYPE_ICON_MAP: Record<MessageType, IconName> = {
  error: 'task-deleted',
  success: 'check',
  warning: 'alert',
  info: 'info',
  idea: 'lightbulb'
}

// logically arrays only used when warnings/errors
const MESSAGE_TYPE_ARRAY_PREFIX_MAP: Record<MessageType, string> = {
  error: 'errors',
  success: '',
  warning: 'warnings',
  info: '',
  idea: ''
}

// Note: currently this comp is nearly always wrapped in a Box with margin-bottom 16px
// added 'margin' prop to make this simpler

export const Message = ({
  message,
  'data-testid': dataTestId,
  type = 'info',
  role,
  children,
  onClose,
  margin
}: MessageProps) => {
  // Make sure nothing is rendered if input is blank. NOTE component should not render regardless - this is just a fallback
  if ((!message || message.length === 0) && !children) {
    return null
  }

  return (
    <Box
      direction="row"
      pad="small"
      gap="xsmall"
      background={`message-${type}-bg`}
      round="8px"
      aria-live="polite"
      role={role ?? (type === 'error' ? 'alert' : undefined)}
      data-testid={dataTestId}
      flex={false}
      align="start"
      css={`
        margin-bottom: ${margin ? '16px' : undefined};
        white-space: normal;
        li::marker {
          color: ${themeColor(`message-${type}`)};
        }
        a {
          text-decoration: underline;
          color: ${themeColor(`message-${type}`)};
        }
      `}
    >
      <Icon icon={MESSAGE_TYPE_ICON_MAP[type]} color={`message-${type}`} />
      <Box alignSelf="center">
        {children ? (
          <MessageText color={`message-${type}`}>{children}</MessageText>
        ) : !Array.isArray(message) ? (
          <MessageText color={`message-${type}`}>{message}</MessageText>
        ) : message.length === 1 ? (
          <MessageText color={`message-${type}`}>{message[0]}</MessageText>
        ) : (
          <>
            <Text color={`message-${type}`}>{`${
              message.length + ' ' + MESSAGE_TYPE_ARRAY_PREFIX_MAP[type]
            } found`}</Text>
            <Box tag="ul" pad={{ left: 'medium' }} margin="none">
              {message.map((content, i) => (
                <Box as="li" key={`${content}-${i}`}>
                  <MessageText color={`message-${type}`}>{content}</MessageText>
                </Box>
              ))}
            </Box>
          </>
        )}
      </Box>
      {onClose && <Button plain onClick={onClose} icon="close" iconColor={`message-${type}`} />}
    </Box>
  )
}

const MessageText = ({ color, children }: { color: string; children: ReactNode }) => {
  if (typeof children === 'string') {
    return (
      <Text color={color}>
        <Markdown>{children}</Markdown>
      </Text>
    )
  } else {
    return (
      <Text color={color} css="white-space:pre-wrap;">
        {children}
      </Text>
    )
  }
}
