import { Placement } from '@popperjs/core'
import { DirectionType } from 'grommet/utils'
import styled, { css } from 'styled-components/macro'

import { IconName } from '../../../icon/icon'
import { IconButton } from '../../../button/button'
import { Box } from '../../../layout'
import { resolveColor, themeColor, themeEdgeSize } from '../../../theme'
import { Text, TextProps } from '../../../typography'

export type FormFieldComponentProps = {
  hasIcon?: boolean
  hasError?: boolean
  required?: boolean
  disabled?: boolean
  clickable?: boolean
  truncate?: boolean
  direction?: DirectionType
}

export const HelpText = ({
  text,
  icon = 'help',
  iconSize = 'small',
  iconPlain = false,
  position = 'left',
  onClick
}: {
  text: string
  icon?: IconName
  iconSize?: string
  iconPlain?: boolean
  position?: Placement
  onClick?: () => void
}) => {
  return (
    <IconButton
      icon={icon}
      tabIndex={-1}
      label={text}
      tipPlacement={position}
      size={iconSize}
      iconPlain={iconPlain}
      onClick={onClick}
    />
  )
}

export type FieldLabelTextProps = FormFieldComponentProps & TextProps
export const FieldLabelSuffix = styled(Text)`
  line-height: unset;
  white-space: nowrap;
  overflow: hidden;
  flex-shrink: 0;
`

export const FieldLabelTextWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  justify-content: space-between;
  display: flex;
  width: 100%;
  gap: 8px;
`

export const FieldLabelText = styled(Text)<FieldLabelTextProps>`
  white-space: nowrap;
  pointer-events: none;
  line-height: unset;
  display: block;
  font-size: inherit;
  color: ${props => themeColor(props.hasError ? 'error' : props.disabled ? 'text-disabled' : 'text-light')};

  ${({ required }) =>
    required &&
    css`
      &:after {
        padding-left: 4px;
        content: '*';
      }
    `};
`

export type FormFieldLabelBoxProps = FormFieldComponentProps & {
  tag?: string
  'aria-label'?: string
  'aria-labelledby'?: string
  id?: string
}

export const FormFieldLabelBox = styled(Box).attrs((props: FormFieldLabelBoxProps) => ({
  tag: props.tag ?? 'label'
}))`
  width: 100%;
  min-height: 32px;
  justify-content: center;
  line-height: 1;
  position: relative;
  color: ${props => themeColor(props.disabled ? 'text-disabled' : 'text')};
  cursor: default;

  ${props =>
    props.clickable &&
    css`
      cursor: default !important;
      input {
        cursor: pointer !important;
        color: ${themeColor('text')};
        &:hover {
          color: ${themeColor('primary')};
        }
        &:read-only {
          cursor: pointer !important;
        }
      }
    `}

  &:focus,
  &:active,
  &:focus-within {
    ${FieldLabelText} {
      color: ${props => themeColor(props.disabled ? 'text-disabled' : props.hasError ? 'error' : 'primary')};
    }
  }

  input[type='text'],
  input[type='number'],
  input[type='password'] {
    padding: 7px;
    padding-left: ${props => (props.hasIcon ? '8px' : '0')};
    padding-right: 0;
  }

  input[type='text']:focus,
  input[type='number']:focus,
  input[type='password']:focus {
    outline: none;
    line-height: 1;
  }

  // radiobox and checkbox grommet styles use opacity to indicate disabled state
  label[disabled] {
    opacity: 1 !important;
  }
`

export const FormFieldBorder = styled(Box)<FormFieldComponentProps>`
  padding-bottom: 1.5px;

  ${props =>
    createFormFieldBorder(resolveColor(props.hasError ? 'error' : props.disabled ? 'bg-2' : 'bg-4', props.theme))};

  &:focus,
  &:active,
  &:focus:active,
  &:focus-within,
  &:focus-within:active {
    ${props =>
      createFormFieldBorder(resolveColor(props.hasError ? 'error' : props.disabled ? 'bg-2' : 'primary', props.theme))}
  }
`

export const FieldBox = styled(Box).attrs(() => ({
  align: 'center',
  width: '100%',
  flex: false
}))<FormFieldComponentProps>`
  flex-direction: ${props => props.direction ?? 'row'};
  input {
    font-size: 15px;
    font-weight: normal;
  }
  ${props =>
    props.truncate &&
    css`
      input {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    `}
  ${props =>
    props.hasIcon &&
    css`
      input[type='text'],
      input[type='password'],
      input[type='number'] {
        // Note: this has been overwritten in NumberInput to 2px (time-picker.tsx)
        // consider making changes there if making changes to form field input globally
        padding-left: ${themeEdgeSize('xxsmall')} !important;
      }
    `};
`

export const FormFieldInlineErrorBox = styled(Box)``

const FormFieldStyles = css`
  min-height: 64px;
`

/**
 * Component that wraps form fields that don't animate their labels
 */
export const StaticFormField = styled(Box)`
  // TODO: need to put in line equivalent to max-width for AnimatedFormFields below

  ${FormFieldStyles}
`

/**
 * Component that wraps form fields that animate their labels
 */
export const AnimatedFormField = styled(Box)<{
  labelSize: 'small' | 'medium'
  hasIcon?: boolean
  hasTooltipText?: boolean
}>`
  ${FormFieldStyles}

  ${FieldLabelTextWrap} {
    left: ${props => (props.labelSize === 'small' ? '0' : props.hasIcon ? '32px' : '0')};
    top: ${props => (props.labelSize === 'small' ? '0' : '22px')};
    // TODO: replace with theme text
    font-size: ${props => (props.labelSize === 'small' ? '13px' : '15px')};
    // TODO: replace with theme animation
    transition: all 0.15s cubic-bezier(0.35, 0, 0.25, 1);

    // Note: this was needed to handle the long placeholder label when form field is shrunk and has an icon
    max-width: ${props =>
      props.labelSize === 'small' || (!props.hasIcon && !props.hasTooltipText)
        ? '100%'
        : !props.hasTooltipText && props.hasIcon
        ? 'calc(100% - 32px) !important'
        : props.hasTooltipText && !props.hasIcon
        ? 'calc(100% - 24px) !important'
        : 'calc(100% - 24px - 32px) !important'};
  }
`

export const FieldInlineErrorText = styled(Text).attrs(() => ({
  size: 'small',
  color: 'error'
}))`
  line-height: 1.2em;
`

// TODO: all places in this function with 3px and 5px should be changed back to 6px and 12px to fit new design
export const createFormFieldBorder = (color: string) => css`
  background-size: 1.5px 100%, 100% 1.5px, 1.5px 100%, 100% 1.5px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  background-image: repeating-linear-gradient(
      180deg,
      transparent,
      transparent 3px,
      transparent 3px,
      transparent 5px,
      transparent 5px
    ),
    repeating-linear-gradient(270deg, transparent, transparent 3px, transparent 3px, transparent 5px, transparent 5px),
    repeating-linear-gradient(0deg, transparent, transparent 3px, transparent 3px, transparent 5px, transparent 5px),
    repeating-linear-gradient(90deg, ${color}, ${color} 3px, transparent 3px, transparent 5px, ${color} 5px);
`
