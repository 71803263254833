import { Editor, Transforms } from 'slate'

import { deserialize } from '../utils/serializer'

export const withLinks = (editor: Editor) => {
  const { insertData, isInline } = editor

  editor.isInline = element => {
    return element.type === 'link' ? true : isInline(element)
  }

  editor.insertData = data => {
    const html = data.getData('text/html')

    if (html) {
      const parsed = new DOMParser().parseFromString(html, 'text/html')
      const fragment = deserialize(parsed.body)
      Transforms.insertFragment(editor, fragment as any)
      return
    }

    insertData(data)
  }

  return editor
}
