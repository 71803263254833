import { useEffect, useState } from 'react'
import { useSlate } from 'slate-react'
import styled from 'styled-components/macro'

import { LinkPayload, TextEditorLinkModal } from './text-editor-link-modal'
import { Button, IconButton, ViewToggleIconButton } from '../../../button'
import { IconName } from '../../../icon'
import { Box } from '../../../layout/box'
import { themeColor, themeElevation } from '../../../theme'
import { useRovingTabIndex } from '../../../utilities/use-roving-tab-index'
import { Format, Type } from '../text-editor-types'
import {
  addColumn,
  addRow,
  insertLink,
  insertTable,
  isBlockActive,
  isMarkActive,
  removeColumn,
  removeRow,
  TEXT_ALIGN_TYPES,
  toggleBlock,
  toggleMark
} from '../utils'

export const TextEditorToolbar = ({
  toggleCodeEditor,
  codeEditorOpen
}: {
  toggleCodeEditor: () => void
  codeEditorOpen: boolean
}) => {
  const editor = useSlate()
  const { setToolbarElement } = useRovingTabIndex()
  const [linkModalOpen, setLinkModalOpen] = useState(false)
  const [tableMenuOpen, setTableMenuOpen] = useState(false)
  const tableBlockActive = isBlockActive(editor, 'table' as Type)

  const handleInsertLink = (data: LinkPayload) => {
    insertLink(editor, data)
  }

  const toggleTableMenu = () => {
    setTableMenuOpen(!tableMenuOpen)
  }

  const handleTableOperation = (operation: void) => {
    setTableMenuOpen(false)
    return operation
  }

  useEffect(() => {
    if (!tableBlockActive && tableMenuOpen) {
      setTableMenuOpen(false)
    }
  }, [tableBlockActive])

  return (
    <Box
      direction="row"
      ref={setToolbarElement}
      role="toolbar"
      gap="none"
      css={`
        background: ${themeColor('bg-1')};
        padding: 2px;
        border-radius: 8px;
        margin-top: 4px;
      `}
    >
      {!codeEditorOpen && (
        <>
          {/* NOTE: this hidden button is a hack becuase the first button in the toolbar is not rendering properly. Can't figure out why
          so for now the below is in place. Need to figure out a long term fix. */}
          <IconButton disableTooltip data-roving-tabindex-ignore="true" label="dummy" icon="add" css="display: none;" />
          <MarkButton format="bold" icon="bold" />
          <MarkButton format="italic" icon="italic" />
          <MarkButton format="underline" icon="underline" />
          {/* <MarkButton format="code" icon="code" /> Marks as code style. */}
          <BlockButton format="bulleted-list" icon="list-bulleted" />
          <BlockButton format="numbered-list" icon="list-numbered" />
          {/* Sets text alignment on block. Example for use if adding alignment buttons to editor. See 'Align' types. */}
          {/* <BlockButton format="center" icon="align-center" /> */}
          <ToolbarButton
            icon="link"
            label="Link"
            isActive={isBlockActive(editor, 'link' as Type)}
            onClick={() => setLinkModalOpen(true)}
          />
          <Box
            direction="row"
            css={`
              position: relative;
            `}
          >
            <ToolbarButton
              icon="table"
              isActive={tableBlockActive}
              onClick={tableBlockActive ? toggleTableMenu : () => insertTable(editor)}
              aria-hidden={tableBlockActive ? true : false}
              tabIndex={tableBlockActive ? -1 : 0}
              label={tableBlockActive ? '' : 'Add table'}
            />
            {tableBlockActive && (
              <IconButton
                icon="caret-down"
                size="medium"
                onClick={toggleTableMenu}
                aria-haspopup={true}
                aria-expanded={!!tableMenuOpen}
                label="Table menu toggle"
                css={`
                  margin-left: -4px;
                `}
                disableTooltip
              />
            )}
            {/* NOTE need to replace this with our normal menu comp (small) */}
            {tableMenuOpen && tableBlockActive && (
              <Box
                css={`
                  background: ${themeColor('menu-bg')};
                  position: absolute;
                  z-index: 1;
                  top: 32px;
                  width: 130px;
                  max-width: none;
                  right: 0;
                  padding: 8px;
                  border-radius: 8px;
                  box-shadow: ${themeElevation('medium')};
                `}
                role="menu"
              >
                <TableButton plain onClick={() => handleTableOperation(addRow(editor))} role="menuitem">
                  Add row
                </TableButton>
                <TableButton plain onClick={() => handleTableOperation(removeRow(editor))} role="menuitem">
                  Remove row
                </TableButton>
                <TableButton plain onClick={() => handleTableOperation(addColumn(editor))} role="menuitem">
                  Add column
                </TableButton>
                <TableButton plain onClick={() => handleTableOperation(removeColumn(editor))} role="menuitem">
                  Remove column
                </TableButton>
              </Box>
            )}
          </Box>
        </>
      )}
      <Box flex="grow" />
      <ToolbarButton
        icon="code"
        data-roving-tabindex-ignore={codeEditorOpen ? 'true' : undefined}
        isActive={codeEditorOpen}
        label="Toggle code editor"
        onClick={toggleCodeEditor}
      />
      <TextEditorLinkModal
        editor={editor}
        open={linkModalOpen}
        onClose={() => setLinkModalOpen(false)}
        onInsertLink={handleInsertLink}
      />
    </Box>
  )
}

const BlockButton = ({ format, icon }: { format: string; icon: IconName }) => {
  const editor = useSlate()

  return (
    <ToolbarButton
      icon={icon}
      label={format}
      isActive={isBlockActive(editor, format as Type, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type')}
      onClick={() => toggleBlock(editor, format as Type)}
      onMouseDown={event => {
        event.preventDefault()
      }}
    />
  )
}

const MarkButton = ({ format, icon }: { format: Format; icon: IconName }) => {
  const editor = useSlate()

  return (
    <ToolbarButton
      icon={icon}
      label={format}
      isActive={isMarkActive(editor, format)}
      onClick={() => toggleMark(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
      }}
    />
  )
}

const TableButton = styled(Button)`
  padding: 4px;
  border-radius: 4px;
  color: ${themeColor('text-light')};
  font-size: 13px;
  width: 100%;
  &:hover {
    background-color: ${themeColor('bg-1')};
  }
`

const ToolbarButton = styled(ViewToggleIconButton).attrs(() => ({
  size: 'medium',
  disableTooltip: true
}))``
