import { useQuery } from 'react-query'

import { apiClient, ApiError } from 'main/services/api/api-client'
import { apiClientCompat_UNSTABLE } from 'main/services/api/api-client-unstable'
import { QueryKeys } from 'main/services/queries/query-keys'
import { Account } from 'main/services/queries/types'
import { ConfigModel } from 'main/data-access'

export type PartialAccount = Pick<
  Account,
  | 'account_type_name'
  | 'archived'
  | 'created_at'
  | 'events_count'
  | 'icon_name'
  | 'id'
  | 'name'
  | 'projects_count'
  | 'runbooks_count'
  | 'slug'
  | 'title'
  | 'updated_at'
  | 'users_count'
>

// add meta
export type AccountsResponseType = {
  meta: {} // has account types
  // maybe better to do with omit lol
  accounts: PartialAccount[]
}

export function useAccountsQuery() {
  const isReactRunbookEnabled = ConfigModel.useIsFeatureEnabled('react_runbook')
  const client = isReactRunbookEnabled ? (apiClientCompat_UNSTABLE as typeof apiClient) : apiClient
  return useQuery<AccountsResponseType, ApiError>([QueryKeys.Accounts], async () => {
    const { data } = await client.get<AccountsResponseType>({ url: 'accounts' })

    return data
  })
}
