import { useMutation } from 'react-query'

import { RunbookDashboardShareEmail } from './share-runbook-dashboard-types'
import { apiClient } from 'main/services/api'

export const useSendRunbookDashboardEmailMutation = () => {
  return useMutation(
    'share-runbook-dashboard-email',
    async ({ id, ...email }: RunbookDashboardShareEmail & { id: number }) => {
      return await apiClient.post<RunbookDashboardShareEmail, void>({
        url: `dashboards/${id}/send_mail`,
        data: email
      })
    }
  )
}
