import { ReactNode, UIEvent, useContext, useEffect, useRef, useState } from 'react'
import { Footer, Heading, Layer, ResponsiveContext, ThemeContext } from 'grommet'
import FocusLock, { MoveFocusInside } from 'react-focus-lock'
import { useMeasure } from 'react-use'
import styled, { css } from 'styled-components/macro'
import tc from 'tinycolor2'

import { Button, IconButton } from '../button'
import { IconName } from '../icon'
import { Box } from '../layout'
import { resolveColor } from '../theme'
import { Text } from '../typography'
import { useInitialMount } from '../utilities'

export type ModalProps = {
  /** animate the appearance of the modal */
  animate?: boolean | 'in' | 'out'
  /** custom cancel button icon */
  cancelIcon?: IconName
  /** custom cancel button text */
  cancelText?: string
  /** render the cancel button */
  hasCancelButton?: boolean
  /** the testing id */
  'data-testid'?: string
  /** hides close and cancel buttons and overrides ESC key action */
  dismissible?: boolean
  /** contents of the modal */
  children?: ReactNode
  /** custom confirm button icon */
  confirmIcon?: IconName | null
  /** custom confirm button text */
  confirmText?: string
  /** custom confirm button data-cy attribute */
  confirmTestId?: string
  /** disables the confirm button */
  confirmDisabled?: boolean
  /** adds a custom button into the footer */
  customButton?: ReactNode
  /** hides the footer of the modal */
  hideFooter?: boolean
  /** is the modal loading? */
  loading?: boolean
  /** confirm button's loading text */
  loadingText?: string
  /** disables the focus trap (ie: to allow drag and drop within modal) */
  disableFocusLock?: boolean
  /** callback for back button / if defined it renders the button  */
  onClickBack?: () => void
  onClose?: () => void
  /**  callback for confirm button / if defined it enables the button */
  onClickConfirm?: () => void
  /**  callback after the modal is closed */
  onAfterClose?: () => void
  /**  sets focus on confirm button after modal opens */
  focusConfirmButton?: boolean
  /**  is the modal open? */
  open?: boolean
  /** title of the modal (header) */
  title: string | ReactNode
  /** component placed in front of the title (header) */
  titlePrefix?: ReactNode
  /** component placed after the title (header) */
  titleSuffix?: ReactNode
  width?: number
  /** add zIndex > 100 to place one modal on top of another modal */
  zIndex?: number
}

const MODAL_HEADER_OFFSET = 52
const MODAL_FOOTER_OFFSET = 52
const MODAL_CONTENT_OFFSET = 48

const ANIMATION_DURATION = 200 // from grommet StyledLayer

export const Modal = ({
  animate: animateProp = true,
  cancelIcon = 'cancel',
  cancelText = 'Cancel',
  hasCancelButton = true,
  'data-testid': dataTestId,
  dismissible = true,
  children,
  confirmIcon = 'add',
  confirmText = 'Save',
  confirmTestId,
  confirmDisabled,
  customButton,
  hideFooter = false,
  loading = false,
  loadingText,
  disableFocusLock = false,
  onClickBack,
  onClose,
  onClickConfirm,
  onAfterClose,
  focusConfirmButton = false,
  open: controlledIsOpen,
  title,
  titlePrefix,
  titleSuffix,
  width,
  zIndex
}: ModalProps) => {
  const [animate, setAnimate] = useState(typeof animateProp === 'boolean' ? animateProp : animateProp === 'in')
  const breakpointSize = useContext(ResponsiveContext)
  const marginOffset = breakpointSize === 'small' ? 0 : 8
  const headerOffset = MODAL_HEADER_OFFSET
  const footerOffset = hideFooter ? 0 : MODAL_FOOTER_OFFSET
  const paddingOffset = 0
  const contentOffset = marginOffset + marginOffset + footerOffset + headerOffset + MODAL_CONTENT_OFFSET
  const maxHeight = window.innerHeight - contentOffset
  const [innerContentRef, { height: innerContentHeight }] = useMeasure<HTMLDivElement>()
  const confirmButtonRef = useRef<HTMLButtonElement | null>(null)

  const [isOpen, setIsOpen] = useState(controlledIsOpen)
  const [showGradient, setShowGradient] = useState(innerContentHeight > maxHeight)
  const initialMount = useInitialMount()

  useEffect(() => {
    setIsOpen(controlledIsOpen)
  }, [controlledIsOpen])

  useEffect(() => {
    if (initialMount) return
    if (!isOpen) {
      handleAfterClose()
    }
  }, [isOpen])

  const setFocusOnConfirmButton = () => {
    if (focusConfirmButton && confirmButtonRef.current) {
      confirmButtonRef.current.focus()
    }
  }

  const handleKeyClose = (e: any) => {
    if (e.key === 'Enter' || e.key === 'Space') handleClickClose()
  }

  const handleClickClose = () => {
    if (onClose) {
      onClose()
    } else {
      setIsOpen(false)
    }
  }

  const handleAfterClose = () => {
    if (onAfterClose) {
      setTimeout(() => onAfterClose(), ANIMATION_DURATION)
    }
  }

  const handleConfirm = () => {
    if (typeof animateProp === 'string') setAnimate(animateProp === 'out')
    onClickConfirm?.()
  }

  useEffect(() => {
    const offset = maxHeight - innerContentHeight
    setShowGradient(offset - paddingOffset <= 0)
  }, [maxHeight, innerContentHeight, paddingOffset])

  const handleScrollContent = (event: UIEvent<HTMLElement>) => {
    const target = event.currentTarget as HTMLDivElement

    if (target) {
      const scrollPosition = target.scrollTop
      const buffer = 5

      if (target.scrollHeight - scrollPosition <= maxHeight + buffer) {
        if (showGradient) setShowGradient(false)
      } else if (scrollPosition > 0) {
        setShowGradient(true)
      }
    }
  }

  return !isOpen ? (
    <></>
  ) : (
    <ThemeContext.Extend
      value={{
        layer: {
          extend: css`
            z-index: ${zIndex ?? 100};

            ${!animate &&
            css`
              .modal-prevent-animation {
                animation: none;
              }
            `}
          `,
          container: {
            elevation: 'xlarge',
            extend: css`
              > a {
                outline: none;
              }
            `
          },
          overlay: {
            background: 'rgba(0,0,0,0.08)'
          },
          border: {
            intelligentRounding: true,
            radius: '16px'
          }
        }
      }}
    >
      <Layer
        modal
        data-testid={dataTestId}
        margin={breakpointSize !== 'small' ? 'xsmall' : undefined}
        onEsc={dismissible ? handleClickClose : undefined}
        onAnimationEnd={setFocusOnConfirmButton}
        aria-modal="true"
        role="dialog"
        className="react-modal-open modal-prevent-animation"
      >
        <FocusLock disabled={process.env.NODE_ENV === 'test' || disableFocusLock}>
          <Box
            css="position: relative"
            background="modal-bg"
            round="16px"
            fill="vertical"
            pad={{
              vertical: '24px',
              left: '24px',
              right: '16px'
            }}
            width={width ? `${width}px` : breakpointSize !== 'small' ? '570px' : undefined}
          >
            <Box
              height={{ min: '52px', max: '52px' }}
              pad={{ right: '8px', bottom: '12px' }}
              direction="row"
              justify="between"
              align="center"
              responsive={false}
            >
              <Box direction="row" align="center" gap="xsmall">
                {onClickBack && (
                  <IconButton
                    disableTooltip
                    disabled={loading}
                    label="Back"
                    icon="arrow-back"
                    ref={ref => ref?.blur()}
                    onClick={() => onClickBack()}
                  />
                )}
                <Box direction="row" gap="small" align="center">
                  {titlePrefix && <Box flex={false}>{titlePrefix}</Box>}
                  {/* TODO: have way to customize the heading level (h1, h2, etc) */}
                  <Heading
                    css="font-weight: 700; font-size: 20px; margin: 0; max-width: 100%; line-height: 25px;"
                    level={2}
                  >
                    {title}
                  </Heading>
                  {titleSuffix}
                </Box>
              </Box>
              {dismissible && (
                <IconButton
                  label="Close"
                  icon="close"
                  data-testid="modal-close-button"
                  disableTooltip
                  disabled={loading}
                  onClick={handleClickClose}
                />
              )}
            </Box>
            <MoveFocusInside>
              <BodyContent
                hideFooter={hideFooter}
                showGradient={showGradient}
                onScroll={handleScrollContent}
                height={{ max: `calc(100vh - ${contentOffset}px)` }}
                responsive={false}
                flex="grow"
                overflow="auto"
              >
                <Box flex={false} ref={innerContentRef} pad={{ right: 'xsmall' }}>
                  {typeof children === 'string' ? <Text>{children}</Text> : children}
                </Box>
              </BodyContent>
            </MoveFocusInside>

            {!hideFooter && (
              <Footer
                height={{ min: '52px', max: '52px' }}
                pad={{ top: 'small', right: 'xsmall' }}
                responsive={false}
                direction="row"
                justify="between"
                data-testid="modal-footer"
              >
                <Box>{customButton}</Box>
                <Box gap="xsmall" direction="row">
                  {hasCancelButton && dismissible && (
                    <Button
                      tertiary
                      disabled={loading}
                      label={cancelText}
                      icon={cancelIcon}
                      onClick={handleClickClose}
                      onKeyUpCapture={handleKeyClose}
                    />
                  )}
                  <Button
                    ref={confirmButtonRef}
                    primary
                    disabled={loading || !onClickConfirm || confirmDisabled}
                    loading={loading}
                    label={loading ? loadingText ?? confirmText : confirmText}
                    icon={confirmIcon ?? undefined}
                    onClick={handleConfirm}
                    data-testid={confirmText}
                    data-cy={confirmTestId}
                  />
                </Box>
              </Footer>
            )}
          </Box>
        </FocusLock>
      </Layer>
    </ThemeContext.Extend>
  )
}

const BodyContent = styled(Box)<{ showGradient: boolean; hideFooter: boolean }>`
  ${({ showGradient, hideFooter, theme }) =>
    showGradient &&
    css`
      &::after {
        content: '';
        position: absolute;
        bottom: ${hideFooter ? '24px' : '76px'};
        left: 0;
        width: 100%;
        height: 40px;
        background: linear-gradient(
          to top,
          ${resolveColor('modal-bg', theme)} 0%,
          ${tc(resolveColor('modal-bg', theme)).setAlpha(0.8).toRgbString()} 50%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    `}
`
