import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { useFormContext } from 'react-hook-form'
import { format, isValid } from 'date-fns'

import { Accordion, AccordionPanel, Box, Text } from '@cutover/react-ui'
import { CustomFieldForm } from 'main/components/shared/custom-field-form'
import { CustomField, CustomFieldGroup, CustomFieldUser, IntegrationActionItem } from 'main/services/queries/types'
import { useLanguage } from 'main/services/hooks'

export type CustomFieldsGroupsFormProps = {
  customFields?: CustomField[]
  groupedCustomFields?: Record<number, CustomField[]>
  customFieldGroupsLookup?: Record<number, CustomFieldGroup>
  customFieldUsers?: CustomFieldUser[]
  iconColor?: string
  namePrefix?: string
  errors?: { [key: string]: any }
  initialActiveIndex?: number[]
  readOnly?: boolean
  disabled?: boolean
  integrationActionItem?: IntegrationActionItem
  integrationGroupedCustomFields?: Record<number, CustomField[]>
  integrationDescription?: string
}

export function CustomFieldsGroupsForm({
  customFields,
  groupedCustomFields,
  customFieldGroupsLookup,
  customFieldUsers,
  iconColor,
  namePrefix,
  errors,
  initialActiveIndex,
  readOnly,
  disabled,
  integrationActionItem,
  integrationGroupedCustomFields,
  integrationDescription
}: CustomFieldsGroupsFormProps) {
  const { t } = useLanguage('common')

  const openPanelIndicesCalc = useMemo(() => {
    const openIndices: number[] = []
    const hasRequiredCustomFields = customFields?.some(cf => cf.required) ?? false
    const hasRequiredIntegrationCustomFields =
      Object.values(integrationGroupedCustomFields || {}).some(group => group.some(cf => cf.required)) ?? false

    const pushIndexBasedOnIntegrationFields = (index: number) => {
      const hasIntegrationFields =
        integrationGroupedCustomFields && Object.keys(integrationGroupedCustomFields).length > 0
      openIndices.push(hasIntegrationFields ? index + 1 : index)
    }

    if (hasRequiredIntegrationCustomFields) {
      openIndices.push(0)
    }
    if (groupedCustomFields) {
      const keys = Object.keys(groupedCustomFields)
      keys.forEach((key, i) => {
        const groupHasRequiredField = groupedCustomFields[key as unknown as number].some(cf => cf.required)
        if (groupHasRequiredField) {
          pushIndexBasedOnIntegrationFields(i)
        }
      })
      if (hasRequiredCustomFields) {
        pushIndexBasedOnIntegrationFields(keys.length)
      }
    } else if (hasRequiredCustomFields) {
      openIndices.push(0)
    }

    return initialActiveIndex || openIndices
  }, [customFields, groupedCustomFields, initialActiveIndex, integrationGroupedCustomFields])
  const [openPanelIndices, setOpenPanelIndices] = useState(openPanelIndicesCalc)

  const handleAccordionClick = (indices: number[]) => {
    setOpenPanelIndices(indices ?? [])
  }

  const customFieldIds = customFields?.map(cf => cf.id) ?? []
  const customFieldHasErrors = customFieldIds.some(id => (errors?.field_values as unknown as [])?.[id])

  const groupIdsWithErrors = Object.keys(groupedCustomFields ?? [])
    .filter(groupId => {
      const groupCustomFieldIds = (groupedCustomFields ?? {})[groupId as unknown as number].map(cf => cf.id)
      return groupCustomFieldIds.some(id => (errors?.field_values as unknown as [])?.[id])
    })
    .map(Number)

  const integrationGroupIdsWithErrors = Object.keys(integrationGroupedCustomFields ?? [])
    .filter(groupId => {
      const groupCustomFieldIds = (integrationGroupedCustomFields ?? {})[groupId as unknown as number].map(cf => cf.id)
      return groupCustomFieldIds.some(id => (errors?.field_values as unknown as [])?.[id])
    })
    .map(Number)

  const integrationGroupedCfsKeys = Object.keys(integrationGroupedCustomFields || {})
  const groupedCfsKeys = Object.keys(groupedCustomFields || {})

  const showIntegrationGroupedCfs =
    integrationGroupedCfsKeys.length > 0 && integrationGroupedCustomFields && integrationActionItem
  const showGroupedCfs = groupedCfsKeys.length > 0 && groupedCustomFields && customFieldGroupsLookup
  const showCfs = customFields && customFields.length > 0

  // need check for whether there's any content otherwise an extra div is added to the dom from the accordion
  // component which can cause styling issues especially with row-gap
  return showCfs || showGroupedCfs || showIntegrationGroupedCfs ? (
    <Accordion controlled activeIndex={openPanelIndices} onActive={handleAccordionClick}>
      {showIntegrationGroupedCfs &&
        integrationGroupedCfsKeys.map(groupId => (
          <AccordionPanel
            key={`${groupId}-integration-panel}`}
            integrationIcon={
              <IntegrationIcon
                alt={`${integrationActionItem.name}-icon`}
                src={integrationActionItem.image_url || integrationActionItem.integration_setting.image_url}
              />
            }
            label={integrationActionItem.integration_setting.name}
            hasError={integrationGroupIdsWithErrors.includes(Number(groupId))}
          >
            {integrationDescription && (
              <StyledIntegrationDescription>
                <Text>{integrationDescription}</Text>
              </StyledIntegrationDescription>
            )}
            <CustomFieldForm
              customFields={integrationGroupedCustomFields[groupId as unknown as number].filter(
                cf => !cf.options || !cf.options.readonly
              )}
              customFieldUsers={customFieldUsers}
              errors={errors?.field_values}
              namePrefix={namePrefix}
              readOnly={readOnly}
              disabled={disabled}
            />
            <ReadOnlyIntegrationCustomFields
              customFields={integrationGroupedCustomFields[groupId as unknown as number].filter(
                cf => cf.options && cf.options.readonly
              )}
              label={integrationActionItem.integration_setting.name}
            />
          </AccordionPanel>
        ))}
      {showGroupedCfs &&
        groupedCfsKeys.map(groupId => (
          <AccordionPanel
            key={`${groupId}-panel}`}
            icon={customFieldGroupsLookup[groupId as unknown as number].icon || 'team'}
            iconColor={iconColor}
            label={customFieldGroupsLookup[groupId as unknown as number]?.name}
            hasError={groupIdsWithErrors.includes(Number(groupId))}
          >
            <CustomFieldForm
              customFields={groupedCustomFields[groupId as unknown as number]}
              customFieldUsers={customFieldUsers}
              errors={errors?.field_values}
              namePrefix={namePrefix}
              readOnly={readOnly}
              disabled={disabled}
            />
          </AccordionPanel>
        ))}
      {showCfs && (
        <AccordionPanel
          label={t('customFields')}
          icon="custom-field"
          iconColor={iconColor}
          hasError={customFieldHasErrors}
          data-testid="custom-fields-accordion"
        >
          <CustomFieldForm
            errors={errors?.field_values}
            customFields={customFields}
            customFieldUsers={customFieldUsers}
            namePrefix={namePrefix}
            readOnly={readOnly}
            disabled={disabled}
          />
        </AccordionPanel>
      )}
    </Accordion>
  ) : null
}

// From readonly_custom_field_integration_directive.js. Used to verify if a read only custom field value is an ISO8601 date.
const ISO8601DateTimeRegex =
  /^([\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24\:?00)([\.,]\d+(?!:))?)?(\17[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/

const ReadOnlyIntegrationCustomFields = ({
  customFields = [],
  label
}: {
  customFields: CustomField[]
  label: string
}) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'editPanel' })
  const { getValues } = useFormContext()

  if (customFields.length === 0) return null

  const hasFieldValues = customFields.find(cf => !!getValues().field_values[cf.id]?.value)

  if (hasFieldValues) {
    return (
      <Box gap="small" tabIndex={0}>
        <Text size="small">{t('fields.integrationReadOnlyTitle', { label })}</Text>
        <Box gap="xxsmall">
          {customFields.map(cf => {
            const fieldValue = getValues().field_values[cf.id].value

            if (!!fieldValue) {
              const parsedValue =
                fieldValue.length > 9 && !!fieldValue.match(ISO8601DateTimeRegex) && isValid(new Date(fieldValue))
                  ? format(new Date(fieldValue), 'do MMM yyyy, HH:mm')
                  : fieldValue
              const customFieldName = cf.display_name || cf.name
              return (
                <Text key={cf.id} size="small">
                  {customFieldName}: {parsedValue}
                </Text>
              )
            }
          })}
        </Box>
      </Box>
    )
  } else {
    return null
  }
}

const IntegrationIcon = styled.img`
  height: 22px;
  width: 22px;
`

const StyledIntegrationDescription = styled(Box)`
  text-wrap: wrap;
  padding-top: 13px;
  padding-bottom: 17.5px;
`
