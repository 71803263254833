import { useEffect } from 'react'
import { addMinutes, isPast } from 'date-fns'
import { Heading } from 'grommet'
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form'
import * as yup from 'yup'

import {
  Box,
  DateTimePicker,
  Form,
  RadioboxGroup,
  Select,
  Text,
  TextEditor,
  TextInput,
  themeColor,
  TimePicker,
  WeekdayPicker
} from '@cutover/react-ui'
import { RunbookDashboardShareEmailFormType } from './share-runbook-dashboard-types'
import { RecipientMultiselect, TeamRecipient, UserRecipient } from 'main/components/recipients-multiselect'
import { TimezoneSelect } from 'main/components/shared/form/timezone-select'
import { useLanguage } from 'main/services/hooks'
import { Filter, FilterResponse } from 'main/services/queries/use-filters'
import { SavedView } from 'main/services/queries/use-saved-view-groups'

export type SendRunbookDashboardFormProps = {
  methods: UseFormReturn<RunbookDashboardShareEmailFormType>
  runbookId?: number
  accountId: number
  runbookVersionId?: number
  showAdvancedOptions: boolean
  onAdvancedOptionsClick: () => void
  filterData: FilterResponse | { filters: SavedView[] } | undefined
  isFilterDataLoading: boolean
  context?: 'MRD' | 'SRD'
}
/*
 * Save button is disabled until form is valid.
 * If this changes,
 * errors must be handled { formState: {errors}} = methods
 */
export const ShareRunbookDashboardEmailForm = ({
  methods,
  runbookId,
  accountId,
  runbookVersionId,
  showAdvancedOptions,
  onAdvancedOptionsClick,
  filterData,
  isFilterDataLoading,
  context = 'SRD'
}: SendRunbookDashboardFormProps) => {
  const { t } = useLanguage('dashboard')
  const { control, register, watch, clearErrors } = methods

  const frequencyType = watch('frequencyType')
  const dashboardView = watch('dashboardView')
  const buttonName = showAdvancedOptions ? 'save' : 'share'
  const action = showAdvancedOptions ? 'schedule' : 'send'

  useEffect(() => {
    if (dashboardView === 'entire') {
      clearErrors('filter')
    }
  }, [dashboardView, clearErrors])

  return (
    <FormProvider {...methods}>
      <Form>
        <Text margin={{ bottom: 'small' }}>{t('shareModal.guidance', { buttonName, action })}</Text>
        <Controller
          name="recipients"
          control={control}
          render={({ field: { onChange } }) => (
            <RecipientMultiselect
              required
              runbookId={runbookId}
              accountId={accountId}
              runbookVersionId={runbookVersionId}
              initialSelected={undefined}
              onChange={recipients => {
                const users = (recipients || []).filter(recipient => recipient.type === 'user') as UserRecipient[]
                const teams = (recipients || []).filter(recipient => recipient.type === 'team') as TeamRecipient[]
                const value = {
                  users: users.map(user => user.id || user.handle),
                  teams: teams.map(team => team.id)
                }
                onChange(value)
              }}
              autoFocus
            />
          )}
        />
        <Controller
          name="message"
          control={control}
          defaultValue=""
          render={({ field: { value, onChange } }) => (
            <TextEditor value={value || ''} defaultValue="" label="Message" onChange={onChange} />
          )}
        />

        <Box
          css={`
            display: ${showAdvancedOptions ? 'block' : 'none'};
          `}
        >
          {/* Needs to be replaced with standard approach when exists - have no theme based heading component now and section heads are non-standard */}
          <Heading
            as="h3"
            css={`
              margin-bottom: 12px;
              font-size: 16px;
              font-weight: 400;
              color: ${themeColor('text')};
            `}
          >
            {t('dashboard:shareModal:schedulingOptions')}
          </Heading>

          {context === 'SRD' && (
            <TextInput required {...register('name')} label={t('dashboard:shareModal:titleLabel')} />
          )}
          <Box>
            <Controller
              name="dashboardView"
              control={control}
              defaultValue="entire"
              render={({ field: { onChange, onBlur, ref } }) => (
                <RadioboxGroup
                  onChange={onChange}
                  name="dashboard-view"
                  required
                  label={t('shareModal.dashboardViewLabel')}
                  direction="row"
                  onBlur={onBlur}
                  options={[
                    { value: 'entire', label: t('shareModal.dashboardViewOption') },
                    { value: 'savedFilters', label: t('shareModal.savedFilterOption') }
                  ]}
                  ref={ref}
                />
              )}
            />

            {dashboardView === 'savedFilters' && (
              <Controller
                name="filter"
                control={control}
                render={({ field: { onChange, value, ref }, formState: { errors } }) => {
                  return (
                    <Select
                      data-testid="dashboard-schedule-filter-select"
                      filterKeys={['label']}
                      onChange={onChange}
                      required
                      hasError={!!errors.filter}
                      inputRef={ref}
                      clearable
                      label={t('shareModal.savedFilterOption')}
                      placeholder={t('shareModal.savedFilterOption')}
                      icon="filter"
                      value={value || ''}
                      loading={isFilterDataLoading}
                      options={
                        !!filterData
                          ? filterData.filters.map((filter: Filter | SavedView) => ({
                              label: filter.name,
                              value: filter.id
                            }))
                          : []
                      }
                    />
                  )
                }}
              />
            )}

            <Controller
              name="frequencyType"
              control={control}
              render={({ field: { onChange, onBlur, ref } }) => (
                <RadioboxGroup
                  onChange={onChange}
                  required
                  name="frequency"
                  label={t('shareModal.frequencyLabel')}
                  direction="column"
                  onBlur={onBlur}
                  options={[
                    { value: 'once', label: t('shareModal.frequencyOptionOnce') },
                    { value: 'every_15_min', label: t('shareModal.frequencyOptionEvery15Min') },
                    { value: 'every_30_min', label: t('shareModal.frequencyOptionEvery30Min') },
                    { value: 'hourly', label: t('shareModal.frequencyOptionHourly') },
                    { value: 'daily', label: t('shareModal.frequencyOptionDaily') },
                    { value: 'weekly', label: t('shareModal.frequencyOptionWeekly') }
                  ]}
                  ref={ref}
                />
              )}
            />
            {!!frequencyType && (
              <>
                {frequencyType === 'weekly' && (
                  <Controller
                    control={control}
                    name="frequencyDays"
                    render={({ field: { onChange }, formState: { errors } }) => (
                      <WeekdayPicker
                        required
                        data-testid="schedule-frequency-days"
                        hasError={!!errors.frequencyDays}
                        onChange={(event: any) => event && onChange(event.value)}
                        label={t('shareSchedulePanel.frequencyDayLabel')}
                      />
                    )}
                  />
                )}

                <Box gap="small" direction="row">
                  {context === 'SRD' ? (
                    <Box width="full">
                      <Controller
                        name="startsAt"
                        control={control}
                        render={({ field: { onChange, value, ref, onBlur } }) => (
                          <DateTimePicker
                            required
                            data-testid="runbook-dashboard-schedule-datepicker"
                            value={value || null}
                            onChange={val => {
                              if (!val) return onChange(val)

                              if (isPast(val)) return onChange(addMinutes(Date.now(), 1))
                              onChange(val)
                            }}
                            label={t('shareModal.dateLabel')}
                            minDate={new Date()}
                            calendarPlacement="top-start"
                            inputRef={ref}
                            onBlur={onBlur}
                          />
                        )}
                      />
                    </Box>
                  ) : frequencyType === 'once' ? (
                    <Box width={'100%'}>
                      <Controller
                        name="scheduleTime"
                        control={control}
                        render={({ field: { onChange, value, ref, onBlur } }) => (
                          <DateTimePicker
                            required
                            data-testid="runbook-dashboard-schedule-datepicker"
                            value={value || null}
                            onChange={val => {
                              if (!val) return onChange(val)

                              if (isPast(val)) return onChange(addMinutes(Date.now(), 1))
                              onChange(val)
                            }}
                            label={t('shareModal.dateLabel')}
                            minDate={new Date()}
                            calendarPlacement="top-start"
                            inputRef={ref}
                            onBlur={onBlur}
                          />
                        )}
                      />
                    </Box>
                  ) : ['hourly', 'daily', 'weekly'].includes(frequencyType as string) ? (
                    <Box width={'100%'}>
                      <Controller
                        name="scheduleTime"
                        control={control}
                        render={({ field: { onChange, value, onBlur } }) => (
                          <TimePicker
                            required
                            value={value || new Date()}
                            onChange={val => {
                              if (!val) return onChange(val)
                              onChange(val)
                            }}
                            label={t('dashboard:shareSchedulePanel:timeLabel')}
                            onBlur={onBlur}
                            plain={false}
                          />
                        )}
                      />
                    </Box>
                  ) : null}
                  <Box width={'100%'}>
                    <Controller
                      name="timezone"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TimezoneSelect
                          required
                          label={t('shareModal.timezoneLabel')}
                          value={value}
                          placeholder={t('shareModal.timezoneLabel')}
                          onChange={onChange}
                        />
                      )}
                    />
                  </Box>
                </Box>
                {context === 'MRD' && frequencyType !== 'once' && (
                  <Box gap="small" justify="between" alignContent="center" align="center" direction="row">
                    <Box width={'100%'}>
                      <Controller
                        name="startsAt"
                        control={control}
                        render={({ field: { onChange, value, ref, onBlur } }) => (
                          <DateTimePicker
                            required
                            value={value || null}
                            onChange={val => {
                              if (!val) return onChange(val)

                              if (isPast(val)) return onChange(addMinutes(Date.now(), 1))
                              onChange(val)
                            }}
                            label={t('shareSchedulePanel.startsAtLabel')}
                            minDate={new Date()}
                            calendarPlacement="top-start"
                            inputRef={ref}
                            onBlur={onBlur}
                          />
                        )}
                      />
                    </Box>
                    <Box width={'100%'}>
                      <Controller
                        name="endsAt"
                        control={control}
                        render={({ field: { onChange, value, ref, onBlur } }) => (
                          <DateTimePicker
                            required
                            value={value || null}
                            onChange={val => {
                              if (!val) return onChange(val)

                              if (isPast(val)) return onChange(addMinutes(Date.now(), 1))
                              onChange(val)
                            }}
                            label={t('shareSchedulePanel.endsAtLabel')}
                            minDate={new Date()}
                            calendarPlacement="top-start"
                            inputRef={ref}
                            onBlur={onBlur}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>

        <>
          <Text
            size="small"
            // adds margin bottom because modal scrolls and can result in this not being visible due to the fade-out effect
            css="text-decoration-line: underline; cursor: pointer; margin-bottom: 10px"
            role="button"
            onClick={onAdvancedOptionsClick}
            tabIndex={0}
            onKeyDown={e => {
              if (e.code === 'Enter') {
                onAdvancedOptionsClick()
              }
            }}
          >
            {showAdvancedOptions
              ? t('dashboard:shareModal:hideSchedulingOptions')
              : t('dashboard:shareModal:showSchedulingOptions')}
          </Text>
        </>
      </Form>
    </FormProvider>
  )
}

export const schema = yup.object({
  recipients: yup
    .object()
    .shape(
      {
        teams: yup
          .array()
          .when('users', ([users], schema) => (users?.length ? schema.notRequired() : yup.array().min(1))),
        users: yup
          .array()
          .when('teams', ([teams], schema) => (teams?.length ? schema.notRequired() : yup.array().min(1)))
      },
      [['teams', 'users']]
    )
    .required(),
  message: yup.string().notRequired(),
  name: yup
    .string()
    .when('$scheduling', ([scheduling], schema) => (scheduling ? schema.required() : schema.notRequired())),
  dashboardView: yup
    .string()
    .when('$scheduling', ([scheduling], schema) => (scheduling ? schema.required() : schema.notRequired())),
  filter: yup
    .string()
    .when('$scheduling', ([scheduling], schema) =>
      schema.when(['dashboardView'], ([dashboardView]) =>
        dashboardView === 'savedFilters' && scheduling ? schema.required() : schema.notRequired()
      )
    ),
  frequencyType: yup
    .string()
    .nullable()
    .oneOf(['once', 'every_15_min', 'every_30_min', 'hourly', 'daily', 'weekly', null])
    .when('$scheduling', ([scheduling], schema) => (scheduling ? schema.required() : schema.notRequired())),
  frequencyDays: yup
    .array()
    .when(['$scheduling', 'frequencyType'], ([scheduling, frequencyType], schema) =>
      frequencyType === 'weekly' && scheduling ? yup.array().min(1).required() : schema.notRequired()
    ),
  startsAt: yup
    .date()
    .when('$scheduling', ([scheduling], schema) => (scheduling ? schema.required() : schema.notRequired())),
  timezone: yup
    .string()
    .when('$scheduling', ([scheduling], schema) => (scheduling ? schema.required() : schema.notRequired()))
})

export const mrdSchema = yup.object({
  recipients: yup
    .object()
    .shape(
      {
        teams: yup
          .array()
          .when('users', ([users], schema) => (users?.length ? schema.notRequired() : yup.array().min(1))),
        users: yup
          .array()
          .when('teams', ([teams], schema) => (teams?.length ? schema.notRequired() : yup.array().min(1)))
      },
      [['teams', 'users']]
    )
    .required(),
  message: yup.string().notRequired(),
  dashboardView: yup
    .string()
    .when('$scheduling', ([scheduling], schema) => (scheduling ? schema.required() : schema.notRequired())),
  filter: yup
    .string()
    .when('$scheduling', ([scheduling], schema) =>
      schema.when(['dashboardView'], ([dashboardView]) =>
        dashboardView === 'savedFilters' && scheduling ? schema.required() : schema.notRequired()
      )
    ),
  frequencyType: yup
    .string()
    .nullable()
    .oneOf(['once', 'every_15_min', 'every_30_min', 'hourly', 'daily', 'weekly', null])
    .when('$scheduling', ([scheduling], schema) => (scheduling ? schema.required() : schema.notRequired())),
  frequencyDays: yup
    .array()
    .when(['$scheduling', 'frequencyType'], ([scheduling, frequencyType], schema) =>
      frequencyType === 'weekly' && scheduling ? yup.array().min(1).required() : schema.notRequired()
    ),
  startsAt: yup
    .date()
    .when(['$scheduling', 'frequencyType'], ([scheduling, frequencyType], schema) =>
      scheduling && frequencyType !== 'once' ? schema.required() : schema.notRequired()
    ),
  endsAt: yup
    .date()
    .when(['$scheduling', 'frequencyType'], ([scheduling, frequencyType], schema) =>
      scheduling && frequencyType !== 'once' ? schema.required() : schema.notRequired()
    ),
  scheduleTime: yup.date().when(['$scheduling', 'frequencyType'], ([scheduling, frequnecyType], schema) =>
    // no need to have schedule time if freqency every 15 or 30 min; starts_at becomes schedule_time
    scheduling && !['every_15_min', 'every_30_min'].includes(frequnecyType) ? schema.required() : schema.notRequired()
  ),
  timezone: yup
    .string()
    .when('$scheduling', ([scheduling], schema) => (scheduling ? schema.required() : schema.notRequired()))
})
